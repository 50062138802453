import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  banner: {
    ...Commons.coverBackground,
    backgroundPosition: "top center",
    background: colors.grayBlack,
    width: "100%",
    height: 240,
  },
  rowColumns: {
    ...Commons.flexRow,
    alignItems: "flex-start",
    maxWidth: 1200,
    margin: "0 auto",
    [Commons.smallQuery]: {
      flexDirection: "column",
      maxWidth: "unset",
      padding: "0 12px",
    },
  },
  leftContainer: {
    flex: 1,
    borderRadius: 18,
    boxShadow: colors.shadow,
    backgroundColor: colors.white,
    position: "relative",
    top: -90,
    maxWidth: "25%",
    padding: 12,
    marginLeft: 30,
    [Commons.smallQuery]: {
      marginLeft: 0,
      flex: "unset",
      maxWidth: "unset",
      width: "calc(100% - 24px)",
      top: -30,
      marginTop: -60,
    },
  },
  rightContainer: {
    flex: 3,
    marginLeft: 30,
    [Commons.smallQuery]: {
      marginLeft: 0,
      flex: "unset",
      width: "100%",
    },
  },
  rowInfo: {
    ...Commons.flexColumn,
    [Commons.smallQuery]: {
      flexDirection: "row",
    },
  },
  imageProfile: {
    backgroundColor: colors.grayLight,
    width: "50%",
    aspectRatio: "1 / 1",
    display: "block",
    margin: "30px auto",
    borderRadius: "50%",
    objectFit: "cover",
    [Commons.smallQuery]: {
      width: 90,
      margin: "12px 0",
    },
  },
  contInfo: {
    [Commons.smallQuery]: {
      flex: 1,
      marginLeft: 18,
    },
  },
  name: {
    textAlign: "center",
    margin: "6px 0",
    color: colors.black,
    fontSize: 24,
    fontWeight: "700",
    overflow: "hidden",
  },
  status: {
    ...Commons.flexCenter,
    margin: "6px 0",
    overflow: "hidden",
  },
  labelStatus: {
    textAlign: "center",
    color: colors.black,
    fontSize: 14,
    fontWeight: "500",
    "& svg": {
      fontSize: 24,
    },
  },
  verifiedIcon: {
    color: colors.primary,
  },
  noVerifiedIcon: {
    color: colors.red,
  },
  description: {
    textAlign: "justify",
    margin: "18px 0",
    color: colors.black,
    fontSize: 15,
    fontWeight: "500",
    overflow: "hidden",
  },
  rowActions: {
    ...Commons.flexRow,
    padding: "18px 0",
    justifyContent: "center",
    gap: 18,
  },
  share: {
    ...Commons.defaultButton,
    border: `1px solid ${colors.themeColor}`,
    color: colors.themeColor,
    textAlign: "center",
    fontSize: 16,
    fontWeight: "600",
    padding: "8px 0",
    flex: 1,
    borderRadius: 18,
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: colors.themeColor,
      color: colors.white,
    },
  },
  whatsapp: {
    ...Commons.defaultButton,
    width: 60,
    height: 36,
    color: colors.primary,
    borderRadius: 18,
    border: `1px solid ${colors.primary}`,
    transition: "all 0.3s",
    textAlign: "center",
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white,
    },
  },
  social: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.grayLight,
    color: colors.black,
    minWidth: 42,
    minHeight: 42,
    maxWidth: 42,
    maxHeight: 42,
    fontSize: 24,
    border: `1px solid ${colors.black}`,
    transition: "all 0.3s",
    textAlign: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: colors.black,
      color: colors.white,
    },
  },
  since: {
    textAlign: "center",
    fontSize: 15,
    color: colors.gray50,
    borderTop: `1px solid ${colors.grayBlack}`,
    padding: "36px 0 12px 0",
    marginTop: 18,
  },
  rowTabs: {
    ...Commons.flexRow,
    padding: "24px 0",
    gap: 18,
    [Commons.smallQuery]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  tab: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    color: colors.gray50,
    fontSize: 18,
    padding: "9px 24px",
    textAlign: "center",
    gap: 12,
    borderRadius: 18,
  },
  colorTheme: {
    color: colors.themeColor,
    fontWeight: "600",
  },
  selectedTab: {
    backgroundColor: colors.themeColor,
    color: colors.white,
  },
  contEmpty: {
    ...Commons.flexColumn,
    width: "100%",
    "& svg": {
      fontSize: 96,
      color: colors.gray50,
    },
  },
  emptyMsg: {
    textAlign: "center",
    fontSize: 18,
    color: colors.gray50,
    margin: 0,
    padding: "24px 0 36px 0",
  },
  loadMore: {
    ...Commons.defaultButton,
    textAlign: "center",
    backgroundColor: colors.themeColor,
    color: colors.white,
    fontSize: 18,
    padding: "12px 0",
    borderRadius: 18,
    width: "90%",
    maxWidth: 240,
    margin: "0 auto 48px auto",
    display: "block",
  },
  switchContainer: {
    [Commons.smallQuery]: {
      margin: "0 auto",
    },
  },
  listItems: {
    ...Commons.flexRow,
    flexWrap: "wrap",
    gap: 18,
    marginTop: 18,
    marginBottom: 48,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  itemEvent: {
    width: "calc(50% - 54px)",
    [Commons.smallQuery]: {
      width: "calc(100% - 42px) !important",
      margin: "0 !important",
    },
  },
});

export default styles;
