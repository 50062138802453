import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  "@keyframes moveBottomInfinite": {
    "0%": {
      bottom: -42,
    },
    "50%": {
      bottom: -60,
    },
    "100%": {
      bottom: -42,
    },
  },
  scrollCont: {
    height: "100%",
    overflow: "auto",
    width: "100%",
    backgroundColor: colors.white,
  },
  contInfo: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 18,
    borderTopRightRadius: 18,
    position: "relative",
    zIndex: 1,
    marginTop: -60,
    padding: "0px 12px",
    [Commons.bigQuery]: {
      padding: "0 25vw",
    },
  },
  rowInfo: {
    ...Commons.flexCenter,
    width: "100%",
    paddingTop: 12,
  },

  contPrice: {
    marginLeft: 12,
  },
  price: {
    margin: 0,
    fontSize: 24,
    fontWeight: "600",
    textAlign: "right",
  },
  lblPrice: {
    margin: 0,
    textAlign: "right",
    fontSize: 12,
    marginTop: -3,
  },
  locationInfo: {
    flex: 1,
    marginRight: 12,
  },
  txtLocation: {
    fontSize: 12,
    margin: 0,
    marginBottom: 6,
    "& svg": {
      fontSize: 12,
      marginRight: 6,
    },
  },
  label: {
    fontWeight: "bold",
    fontSize: 15,
    margin: 0,
    padding: "12px 0px",
  },
  description: {
    fontSize: 14,
    margin: 0,
    paddingBottom: 12,
  },

  blank: {
    height: 90,
  },
  contBuy: {
    ...Commons.flexRow,
    justifyContent: "space-between",
    width: "calc(100% - 24px)",
    backgroundColor: colors.white,
    boxShadow: colors.shadow,
    padding: 12,
    // maxWidth: 1176,
    margin: "0 auto",
    borderRadius: 12,
    // border: `1px solid ${colors.grayBlack}`,
    marginTop: 36,
  },
  buttonBook: {
    ...Commons.flexCenter,
    backgroundColor: colors.black,
    borderRadius: 12,
    width: 48,
    height: 48,
    cursor: "pointer",
    "& svg": {
      color: colors.white,
      fontSize: 18,
    },
  },
  buttonBookSlctd: {
    "& svg": {
      color: colors.yellow,
    },
  },
  floatLabelButton: {
    ...Commons.centerHorizontal,
    position: "absolute",
    whiteSpace: "nowrap",
    bottom: -42,
    backgroundColor: colors.themeColor,
    borderRadius: 6,
    padding: "3px 12px 5px 12px",
    textAlign: "center",
    color: colors.white,
    fontWeight: "700",
    animation: "$moveBottomInfinite 2s infinite",
    "& div": {
      ...Commons.centerHorizontal,
      position: "absolute",
      width: 0,
      height: 0,
      borderLeft: `12px solid transparent`,
      borderRight: `12px solid transparent`,
      borderBottom: `12px solid ${colors.themeColor}`,
      top: -12,
    },
  },
  buttonBuy: {
    ...Commons.defaultButton,
    color: colors.white,
    fontSize: 16,
    textAlign: "center",
    width: "50%",
    borderRadius: 12,
    backgroundColor: colors.themeColor,
    padding: "12px 0px",
    maxHeight: 48,
    fontWeight: "600",
    [Commons.smallQuery]: {
      fontSize: 14,
    },
  },
  buttonViewTickets: {
    ...Commons.defaultButton,
    color: colors.white,
    fontSize: 16,
    textAlign: "center",
    borderRadius: 12,
    backgroundColor: colors.black,
    padding: "12px 0px",
    maxHeight: 48,
    fontWeight: "600",
    marginRight: 12,
    width: "50%",
    [Commons.smallQuery]: {
      fontSize: 14,
      marginRight: 6,
    },
  },
  buttonVerify: {
    ...Commons.defaultButton,
    color: colors.themeColor,
    fontSize: 13,
    textAlign: "center",
    width: "50%",
    borderRadius: 12,
    backgroundColor: colors.white,
    padding: "12px 0px",
    maxHeight: 48,
    fontWeight: "600",
    border: `1px solid ${colors.themeColor}`,
  },
  lblPrivate: {
    fontSize: 12,
    flex: 3,
    textAlign: "center",
    border: "1px dashed black",
    padding: 3,
  },
  wrapItems: {
    ...Commons.flexRow,
    flexWrap: "wrap",
  },

  contContact: {
    ...Commons.flexRow,
    margin: 6,
    color: colors.black,
    textDecoration: "none",
  },
  imgContact: {
    ...Commons.coverBackground,
    minWidth: 36,
    maxWidth: 36,
    minHeight: 36,
    maxHeight: 36,
    backgroundColor: colors.white,
    borderRadius: "50%",
    margin: "0 auto",
    border: `2px solid ${colors.themeColor}`,
  },
  infoContact: {
    flex: 1,
    marginLeft: 6,
  },
  txtContact: {
    fontSize: 10,
    margin: 0,
    marginBottom: -2,
  },
  btnTutorial: {
    ...Commons.defaultButton,
    ...Commons.centerHorizontal,
    borderRadius: 12,
    padding: "12px 18px",
    fontSize: 15,
    color: colors.themeColor,
    margin: "0px 0px 12px 0px",
    paddingLeft: 42,
    "& svg": {
      position: "absolute",
      fontSize: 24,
      marginRight: 12,
      top: 8,
      left: 12,
    },
  },
  infoPartnerBtns: {
    textAlign: "center",
    margin: "30px auto 0 auto",
    fontSize: 24,
    maxWidth: 1164,
    borderRadius: 6,
    color: colors.themeColor,
    fontWeight: "600",
    border: `3px dashed ${colors.themeColor}`,
    padding: 18,
    width: "calc(90% - 36px)",
  },
  rowPartnerBtns: {
    ...Commons.flexRow,
    justifyContent: "center",
    margin: "12px auto 6px auto",
    gap: 12,
    backgroundColor: colors.white,
    borderRadius: 12,
    boxShadow: colors.shadow,
    padding: 18,
    maxWidth: 1164,
    width: "calc(90% - 36px)",
    [Commons.smallQuery]: {
      flexWrap: "wrap",
    },
  },
  btnRef: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    justifyContent: "center",
    borderRadius: 12,
    padding: "15px 3px",
    color: colors.white,
    backgroundColor: colors.themeColor,
    flex: 1,
    minWidth: 150,
    "& svg": {
      fontSize: 18,
      marginRight: 6,
    },
    "& div": {
      fontSize: 15,
    },
    [Commons.smallQuery]: {
      minWidth: "calc(100% - 6px)",
    },
  },
  contStats: {
    ...Commons.flexRow,
    border: `3px solid ${colors.themeColor}`,
    borderRadius: 12,
    padding: "0 6px",
    fontSize: 15,
    flex: 1,
    "& p": {
      flex: 4,
    },
    "& b": {
      fontSize: 30,
      flex: 1,
      textAlign: "center",
    },
    [Commons.smallQuery]: {
      minWidth: "calc(100% - 12px)",
    },
  },
  modalPoster: {
    ...Commons.flexCenter,
    position: "fixed",
    top: 84,
    left: 0,
    width: "100%",
    height: "calc(100% - 84px)",
    backgroundColor: colors.black,
    zIndex: 1,
    [Commons.smallQuery]: {
      top: 72,
      height: "calc(100% - 72px)",
    },
  },
  imgPoster: {
    width: "80%",
    height: "80%",
    objectFit: "contain",
  },
  btnClosePoster: {
    ...Commons.flexCenter,
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    maxWidth: 42,
    minWidth: 42,
    maxHeight: 42,
    minHeight: 42,
    position: "absolute",
    top: 12,
    right: 12,
    borderRadius: "50%",
    color: colors.black,
    zIndex: 2,
  },
  videoTutorial: {
    width: "90%",
    height: "auto",
    maxHeight: "90%",
    border: "2px solid white",
    backgroundColor: colors.white,
  },
});

export default styles;
