import React from "react";
import {
  ChevronLeft,
  AccessTime,
  Place,
  AddCircle,
  RemoveCircle,
} from "@material-ui/icons";
import { useTranslation, Trans } from "react-i18next";
import QRCode from "qrcode.react";
import moment from "moment";

import {
  verifyEmail,
  currencyFormat,
  verifyIsNumber,
  returnCurrentLink,
} from "../../utils/validations";
import pageStyles from "./styles/detailTicketsStyles";
import DialogForm from "../../components/dialogForm";
import Avatar from "../../assets/images/avatar.jpeg";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";

const DetailTickets = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const ticketSelectedInitial = state?.ticketSelected?.data;
  const labelPrice =
    ticketSelectedInitial?.currency === "USD" ? "usdPrice" : "price";
  const labelResalePrice =
    ticketSelectedInitial?.currency === "USD"
      ? "resaleUsdPricePayed"
      : "resalePricePayed";
  const assignedPromoter = ticketSelectedInitial?.assignedPromoter;
  const isAdmin = ticketSelectedInitial?.isAdmin;
  const promoters = ticketSelectedInitial?.promoters;
  // const logistics = ticketSelectedInitial?.logistics;
  const [ticketSelected, setTicketSelected] = React.useState(
    ticketSelectedInitial
  );
  const [showPromoters, setShowPromoters] = React.useState(false);
  const [vc, setVc] = React.useState({
    showMore: false,
    event: {},
    showPrompt: false,
    showResaleForm: false,
    priceToSell: "",
    usdPriceToSell: "",
    email: "",
    name: "",
    numId: "",
    lastName: "",
    saleInfo: null,
    ticket2transfer: null,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const onSendForm = async () => {
    try {
      Model.setData("loading", true);

      const infoTickets = await EventsService.postTransferTicket({
        ticket: String(vc.ticket2transfer),
        id: state?.ticketSelected?.uid,
        data: {
          email: vc.email,
          name: vc.name,
          numId: vc.numId,
          lastName: vc.lastName,
        },
      });
      Model.setData("ticketSelected", infoTickets);
      Model.updateAlerts({
        message: `${t(
          "pages.tickets.detailTickets."
        )}Tickets transferido. Ya se envíaron las entradas al usuario ${
          vc.email
        }`,
        variant: "success",
      });
      changeVc({
        showPrompt: false,
        email: "",
        name: "",
        numId: "",
        lastName: "",
      });
    } catch (e) {
      // console.log("Error para transferir ticket: ", String(e));
      Model.updateAlerts({
        message: "Error: " + String(e?.message || e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSendResaleForm = async () => {
    let finishWithoutError = true;
    try {
      let infoTicketSell = {
        name: vc.event.name,
        price: parseInt(vc.priceToSell),
        quantity: 1,
      };

      if (state?.userInfo?.secret?.mercadopago) {
        infoTicketSell.price = parseInt(vc.priceToSell);
      }

      if (state?.userInfo?.secret?.bitcoin) {
        infoTicketSell.usdPrice = parseFloat(
          parseFloat(vc.usdPriceToSell).toFixed(2)
        );
      }

      const tickets2sell = {
        [state?.ticketSelected?.uid]: infoTicketSell,
      };

      changeVc({
        showPrompt: false,
        showResaleForm: false,
      });
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      await EventsService.sellMyTickets({
        tickets: tickets2sell,
        test: window?.location?.host?.includes?.("test."),
      });

      changeVc({ showResaleForm: false });
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.yourTicketIsSelling"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message:
          `${t("pages.tickets.detailTickets.errorSellTicket")}: ` + String(e),
        variant: "error",
      });
      finishWithoutError = false;
    } finally {
      Model.setData("loading", false);
      if (finishWithoutError) {
        history.push(Routes.MY_TRANSACTIONS);
      }
    }
  };

  const confirmTransferTicket = async () => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const infoTickets = await EventsService.confirmTransferTicket({
        id: state?.ticketSelected?.uid,
      });
      Model.setData("ticketSelected", infoTickets);
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.receivedTicket"),
        variant: "success",
      });
      history.push(Routes.MY_TRANSACTIONS);
    } catch (e) {
      // console.log("Error para transferir ticket: ", String(e));
      Model.updateAlerts({
        message: "Error: " + String(e?.message || e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const redeemTransfer = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.detailTickets.beforeContinue"),
      text: t("pages.tickets.detailTickets.rememberTransfer"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: confirmTransferTicket,
    });
  };

  const cancelTransfer = async (ticket2cancel) => {
    try {
      Model.setData("loading", true);

      const infoTickets = await EventsService.cancelTransfer({
        id: state?.ticketSelected?.uid,
        ticket2cancel,
      });
      Model.setData("ticketSelected", infoTickets);
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.transferCanceled"),
        variant: "success",
      });
    } catch (e) {
      // console.log("Error para transferir ticket: ", String(e));
      Model.updateAlerts({
        message: "Error: " + String(e?.message || e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onCancelTransfer = (ticket2cancel) => {
    if (!!ticket2cancel) {
      Model.setData("dialog", {
        open: true,
        title: t("pages.tickets.detailTickets.beforeContinue"),
        text: t("pages.tickets.detailTickets.rememberCancelTransferTicket"),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          cancelTransfer(ticket2cancel);
        },
      });
    }
  };

  const showConfirmTransfer = () => {
    if (!vc.name || !vc.lastName || !vc.numId || !vc.email) {
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.completeDataTry"),
        variant: "error",
      });
    } else if (!verifyEmail(vc.email)) {
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.emailHasBadFormat"),
        variant: "error",
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: t("pages.tickets.detailTickets.confirmationTransfer"),
        text: t("pages.tickets.detailTickets.areSureTransfer", {
          email: vc.email,
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          onSendForm();
        },
      });
    }
  };

  const transferTicket = (index) => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.detailTickets.beforeContinue"),
      text: t("pages.tickets.detailTickets.rememberBeforeTransfer"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        changeVc({
          showPrompt: true,
          email: "",
          name: "",
          numId: "",
          lastName: "",
          ticket2transfer: index,
        });
      },
    });
  };

  const confirmCancelSell = async (index) => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      const infoTickets = await EventsService.cancelSellMyTicket({
        ticket: String(index),
        id: state?.ticketSelected?.uid,
      });
      Model.setData("ticketSelected", infoTickets);
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.sellCanceled"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message:
          `${t("pages.tickets.detailTickets.errorToCancelSell")}: ` + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onCancelSell = (index) => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.detailTickets.beforeContinue"),
      text: t("pages.tickets.detailTickets.rememberCancelSell"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => confirmCancelSell(index),
    });
  };

  const showConfirmResale = () => {
    const intNum = parseInt(vc.priceToSell);
    // const intUsdNum = parseFloat(vc.usdPriceToSell);

    if (
      state?.userInfo?.secret?.mercadopago &&
      !vc.priceToSell
      // || (state?.userInfo?.secret?.bitcoin && !vc.usdPriceToSell)
    ) {
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.completeValueSell"),
        variant: "error",
      });
    } else if (
      state?.userInfo?.secret?.mercadopago &&
      (!verifyIsNumber(vc.priceToSell) || isNaN(intNum) || intNum < 1000)
    ) {
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.badCopPrice"),
        variant: "error",
      });
    }
    // else if (
    //   state?.userInfo?.secret?.bitcoin &&
    //   (!verifyIsNumber(vc.usdPriceToSell) || isNaN(intUsdNum) || intUsdNum <= 0)
    // ) {
    //   Model.updateAlerts({
    //     message: t("pages.tickets.detailTickets.badUsdPrice"),
    //     variant: "error",
    //   });
    // }
    else {
      let labelPrice = "";

      if (state?.userInfo?.secret?.mercadopago) {
        labelPrice = `${currencyFormat(intNum, "COP", 0)} COP`;
      }

      // if (state?.userInfo?.secret?.bitcoin) {
      //   labelPrice =
      //     (labelPrice ? `${labelPrice} y ` : "") +
      //     `${currencyFormat(intUsdNum, "COP", 2)} USD`;
      // }

      Model.setData("dialog", {
        open: true,
        title: t("pages.tickets.detailTickets.resellConfirmation"),
        text: t("pages.tickets.detailTickets.areSureSellTicket", {
          labelPrice,
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: onSendResaleForm,
      });
    }
  };

  const onAcceptSellTerms = (index) => {
    if (
      state?.userInfo?.secret?.mercadopago ||
      state?.userInfo?.secret?.bitcoin
    ) {
      changeVc({
        showResaleForm: true,
        priceToSell: "",
        usdPriceToSell: "",
        ticket2transfer: index,
      });
      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.enterPriceSell"),
        variant: "warning",
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: t("pages.tickets.detailTickets.mustLinkAccount"),
        text: t("pages.tickets.detailTickets.toSellLinkAccounts"),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("pages.tickets.detailTickets.link"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          history.push(Routes.PROFILE);
        },
      });
    }
  };

  const onSellTicket = (index) => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.detailTickets.beforeContinue"),
      text: state?.emailVerified
        ? t("pages.tickets.detailTickets.everySellAccept")
        : t("pages.tickets.detailTickets.mustVerifyEmail"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: state?.emailVerified
        ? t("commons.accept")
        : t("pages.tickets.detailTickets.goToProfile"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        if (state?.emailVerified) {
          onAcceptSellTerms(index);
        } else {
          history.push("/profile");
        }
      },
      link: returnCurrentLink(Routes.RENDER_LEGAL_DOC + "?document=resale"),
    });
  };

  const assignNewPromoter = async (infoNewPromoter) => {
    try {
      Model.setData("loading", true);
      setShowPromoters(false);

      await EventsService.editPromoter({
        ticket: state?.ticketSelected?.uid,
        promoter: infoNewPromoter?.uid,
      });
      Model.setData("ticketSelected", {
        ...state.ticketSelected,
        data: {
          ...state.ticketSelected.data,
          ref: infoNewPromoter?.uid,
        },
      });

      Model.updateAlerts({
        message: t("pages.tickets.detailTickets.sellUpdated"),
        variant: "success",
      });
    } catch (e) {
      // console.log("Error para editar compra: ", String(e));
      Model.updateAlerts({
        message: "Error: " + String(e?.message || e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onNewPromoter = (infoNewPromoter) => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.detailTickets.waitRead"),
      text: t("pages.tickets.detailTickets.rememberThisStep", {
        name: infoNewPromoter?.name || infoNewPromoter?.email,
      }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        assignNewPromoter(infoNewPromoter);
      },
    });
  };

  const returnResellInputs = () => {
    const inputs = [];

    if (state?.userInfo?.secret?.mercadopago) {
      inputs.push({
        label: t("pages.tickets.detailTickets.priceInCop"),
        value: "priceToSell",
      });
    }
    // if (state?.userInfo?.secret?.bitcoin) {
    //   inputs.push({
    //     label: t("pages.tickets.detailTickets.priceInUsd"),
    //     value: "usdPriceToSell",
    //   });
    // }

    return inputs;
  };

  const renderPromotor = () => {
    const refPromoter = ticketSelected?.ref;
    let promoter = {};
    if ((promoters?.length > 0 || assignedPromoter) && refPromoter) {
      if (assignedPromoter) {
        promoter = assignedPromoter;
      } else if (promoters?.length > 0) {
        promoters.forEach((infoPromoter) => {
          if (refPromoter === infoPromoter?.uid) {
            promoter = infoPromoter;
          }
        });
      }
      return (
        <div
          className={styles.containterPromoter}
          onClick={() =>
            history.push("/detailMember", {
              userSelected: promoter,
              event: vc.event,
            })
          }
        >
          <img
            src={promoter?.imageProfile || Avatar}
            className={styles.imagePromoter}
            alt="Promoter"
          />
          <div className={styles.infoPromoter}>
            <p className={styles.labelPromoter}>
              {t("pages.tickets.detailTickets.referedPromotor")}:
            </p>
            <p className={styles.dataPromoter}>{promoter?.name || "-"}</p>
          </div>
        </div>
      );
    } else {
      return showPromoters ? (
        <div className={styles.containterPromoter}>
          <RemoveCircle
            className={styles.iconLabelPromoter}
            onClick={() => setShowPromoters(false)}
          />
          <div className={styles.listPromoters}>
            {promoters?.length > 0 ? (
              promoters.map((infoPromoter) => (
                <div
                  className={styles.containerNewPromoter}
                  onClick={() => onNewPromoter(infoPromoter)}
                  key={infoPromoter?.uid}
                >
                  <img
                    src={infoPromoter?.imageProfile || Avatar}
                    className={styles.imageNewPromoter}
                    alt="Promoter"
                  />
                  <p className={styles.nameNewPromoter}>
                    {infoPromoter?.name || "-"}
                  </p>
                </div>
              ))
            ) : (
              <p
                className={styles.emptyPromoters}
                onClick={() => history.push("team", { event: vc.event })}
              >
                {t("pages.tickets.detailTickets.noPromotorAdd")}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div
          className={styles.containterPromoter}
          onClick={() => setShowPromoters(true)}
        >
          <AddCircle className={styles.iconLabelPromoter} />
          <p className={styles.labelPromoter}>
            {t("pages.tickets.detailTickets.addPromoter")}
          </p>
        </div>
      );
    }
  };

  const renderMessage = () => (
    <div className={styles.contCard}>
      <p className={styles.nameTicket}>
        {t("pages.tickets.detailTickets.sellSummary")}
      </p>
      <div className={styles.row}>
        <div className={styles.flexCol}>
          <span className={styles.smallLbl}>
            {ticketSelected?.tickets?.length} Ticket
            {ticketSelected?.tickets?.length === 1 ? "" : "s"}
          </span>
          <span
            className={[
              styles.smallLblValue,
              ticketSelected?.status === "finished"
                ? styles.colorGreen
                : styles.colorPink,
            ].join(" ")}
          >
            {ticketSelected?.status === "finished"
              ? t("pages.tickets.detailTickets.eventFinished")
              : ticketSelected?.status === "payed"
              ? t("pages.tickets.detailTickets.payed")
              : ticketSelected?.status === "pending"
              ? t("pages.tickets.detailTickets.payPending")
              : ticketSelected?.status === "refunded"
              ? t("pages.tickets.detailTickets.payRefunded")
              : t("pages.tickets.detailTickets.payRejected")}
          </span>
          <span className={styles.smallLbl}>
            {t("pages.tickets.detailTickets.sellDate")}:{" "}
            {moment(ticketSelected?.created, "x").format("DD/MM/YYYY HH:mm")}
          </span>
          <span className={styles.smallLbl}>
            Transaction ID: {state?.ticketSelected?.uid}
          </span>
        </div>
        <div className={styles.flexColR}>
          <span className={styles.smallLbl}>
            {t("pages.tickets.detailTickets.totalPrice")}
          </span>
          <span className={styles.smallLblValue}>
            {currencyFormat(
              isAdmin
                ? ticketSelected?.tickets?.[0]?.[labelPrice]
                : ticketSelected?.selledTickets?.[0]?.[labelResalePrice] ||
                    ticketSelected?.tickets?.[0]?.[labelPrice],
              "COP",
              labelPrice === "usdPrice" ? 2 : 0
            )}{" "}
            {ticketSelected?.currency || "COP"}
          </span>
        </div>
      </div>
      <div className={styles.dashLine} />
      <p className={styles.lblCard}>{vc.event.name}</p>
      {vc.event?.isCancelled ? (
        <div className={styles.lblCancelled}>
          {t("pages.tickets.detailTickets.canceled")}
        </div>
      ) : null}
      <div className={styles.row}>
        <AccessTime className={styles.infoIcon} />
        <span className={styles.infoTxt}>
          {moment(vc.event.date, "x").format("HH:mm | ddd DD MMM, YYYY")}
        </span>
      </div>
      <div className={styles.row}>
        <Place className={styles.infoIcon} />
        <span className={styles.infoTxt}>{vc.event.location}</span>
      </div>
      <p className={styles.smallLblCard}>
        {t("pages.tickets.detailTickets.eventTerms")}
      </p>
      <div className={styles.contTerms}>
        <p className={styles.terms}>
          {vc.showMore === true || vc.showMore === 0
            ? vc.event.terms
            : vc.event.terms
            ? vc.event.terms.slice(0, 240)
            : ""}
          <br />
          <br />- <em>{t("pages.tickets.detailTickets.fraventsTerms")}</em>
        </p>
        {vc.showMore === true || vc.showMore === 0 ? null : (
          <button
            className={styles.viewMoreBtn}
            onClick={() => changeVc({ showMore: 0 })}
          >
            {t("pages.tickets.detailTickets.viewMore")}
          </button>
        )}
      </div>
    </div>
  );

  const returnNameLogistic = (uid) => {
    let promoter = {};
    promoters.forEach((infoPromoter) => {
      if (uid === infoPromoter?.uid) {
        promoter = infoPromoter;
      }
    });
    return promoter?.name;
  };

  const renderActions = (ticket, index) => {
    const isAvailable =
      ticketSelected?.status === "payed" &&
      ticket?.status === "pending" &&
      !state.loading;
    const passLimitResale = ticketSelected?.oldData?.length > 10;
    const isFree = parseInt(ticket?.type) >= vc.event.prices?.length;

    return (
      <>
        {isAvailable && !ticket?.isSelling && !isAdmin ? (
          passLimitResale || ticket?.transferedTo || isFree ? (
            <button
              className={[styles.transferButton, styles.noTransferButton].join(
                " "
              )}
              onClick={() =>
                onCancelTransfer(!passLimitResale && ticket?.transferedTo)
              }
            >
              {passLimitResale
                ? t("pages.tickets.detailTickets.thisTicketMaxTransfer")
                : ticket?.transferedTo
                ? `${t("pages.tickets.detailTickets.cancelTransfer")} (${
                    ticket?.transferedEmail || "ERROR"
                  })`
                : t("pages.tickets.detailTickets.noCortesiaTransfer")}
            </button>
          ) : (
            <button
              className={styles.transferButton}
              onClick={() => transferTicket(index)}
            >
              {t("pages.tickets.detailTickets.transferTicket")}
            </button>
          )
        ) : null}
        {isAvailable &&
        !isAdmin &&
        !ticket?.isSelling &&
        !ticket?.transferedTo &&
        !isFree ? (
          <button
            className={styles.transferButton}
            onClick={() => onSellTicket(index)}
          >
            {t("pages.tickets.detailTickets.publishResell")}
          </button>
        ) : null}
        {isAvailable && ticket?.isSelling && !isAdmin ? (
          <button
            className={[styles.transferButton, styles.noTransferButton].join(
              " "
            )}
            onClick={() => onCancelSell(index)}
          >
            {t("pages.tickets.detailTickets.cancelResell")} (
            {t("pages.tickets.detailTickets.priceResell")}{" "}
            {ticket?.priceToSell
              ? `${currencyFormat(ticket?.priceToSell, "COP", 0)} COP`
              : null}{" "}
            {ticket?.priceToSell && ticket?.usdPriceToSell ? "/" : ""}{" "}
            {ticket?.usdPriceToSell
              ? `${currencyFormat(ticket?.usdPriceToSell, "COP", 2)} USD`
              : null}
            )
          </button>
        ) : null}
      </>
    );
  };

  const renderTickets = () => {
    if (ticketSelected?.tickets?.length > 0) {
      return state.ticketSelected.data.tickets.map((ticket, index) =>
        state.ticketSelected?.data?.statusTransfer === "pending" ? (
          <div className={styles.contCard} key={"item-ticket-" + index}>
            <button onClick={redeemTransfer} className={styles.redeemButton}>
              {t("pages.tickets.detailTickets.redeemTicket")}
            </button>
          </div>
        ) : (
          <div className={styles.contCard} key={"item-ticket-" + index}>
            {vc.event?.secretLocation ? (
              <div className={styles.secretLocation}>
                {t("pages.tickets.detailTickets.location")}:
                <br />
                <span>{vc.event?.secretLocation}</span>
              </div>
            ) : null}
            <p className={styles.nameTicket}>Ticket {ticket.label}</p>
            {state.ticketSelected?.hideQr ||
            ticket.status === "validated" ||
            ticket?.transferedTo ||
            ticket?.isSelling ? null : (
              <div className={styles.contQr}>
                <QRCode
                  level="H"
                  value={`${
                    ticketSelected?.latestRefreshCode ||
                    ticketSelected?.consulted
                  }-${state?.ticketSelected?.uid.slice(0, 6)}-${
                    ticketSelected?.created
                  }-${ticketSelected?.user.slice(0, 6)}-${index}`}
                  size={180}
                />
              </div>
            )}
            <div className={styles.row}>
              <div className={styles.flexCol}>
                <span className={styles.smallLbl}>
                  {t("pages.tickets.detailTickets.ticketStatus")}
                </span>
                <span
                  className={[
                    styles.smallLblValue,
                    ticket.status === "pending"
                      ? styles.colorGreen
                      : styles.colorPink,
                  ].join(" ")}
                >
                  {ticket.status === "pending"
                    ? t("pages.tickets.detailTickets.noUsed")
                    : t("pages.tickets.detailTickets.used")}
                </span>
                <span className={styles.mediumLbl}>
                  <br />
                  <b>{t("commons.name")}</b> {ticketSelected?.client?.name}{" "}
                  {ticketSelected?.client?.lastName}
                </span>
                <span className={styles.mediumLbl}>
                  <b>{t("commons.numId")}</b> {ticketSelected?.client?.numId}
                </span>
                <span className={styles.mediumLbl}>
                  <b>{t("commons.email")}</b> {ticketSelected?.client?.email}
                </span>
                {isAdmin && ticket?.validatedBy ? (
                  <>
                    <span className={[styles.mediumLbl, styles.red].join(" ")}>
                      <br />
                      <b>{t("pages.tickets.detailTickets.scannedDate")}:</b>
                      <br />
                      {moment(
                        ticket?.dateValidated || ticketSelected?.consulted,
                        "x"
                      ).format("DD MMM YYYY hh:mm a")}
                    </span>
                    <span className={[styles.mediumLbl, styles.red].join(" ")}>
                      <b>{t("pages.tickets.detailTickets.whoScanned")}:</b>
                      <br />
                      {returnNameLogistic(ticket?.validatedBy)}
                    </span>
                  </>
                ) : null}
              </div>
              <div className={styles.flexColR}>
                <span className={styles.smallLblRight}>
                  {t("pages.tickets.detailTickets.totalPrice")}
                </span>
                <span className={styles.smallLblValue}>
                  {currencyFormat(
                    isAdmin
                      ? ticket?.[labelPrice]
                      : ticket?.[labelResalePrice] || ticket?.[labelPrice],
                    "COP",
                    labelPrice === "usdPrice" ? 2 : 0
                  )}{" "}
                  {ticketSelected?.currency || "COP"}
                </span>
                <span className={styles.smallLblRight}>
                  {t("pages.tickets.detailTickets.sellDate")}:{" "}
                  {moment(ticketSelected?.created, "x").format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </span>
                <span className={styles.smallLbl}>
                  Transaction ID: {state?.ticketSelected?.uid}
                </span>
              </div>
            </div>
            <div className={styles.dashLine} />
            <p className={styles.lblCard}>{vc.event.name}</p>
            {vc.event?.isCancelled ? (
              <div className={styles.lblCancelled}>
                {t("pages.tickets.detailTickets.canceled")}
              </div>
            ) : null}
            <div className={styles.row}>
              <AccessTime className={styles.infoIcon} />
              <span className={styles.infoTxt}>
                {moment(vc.event.date, "x").format("HH:mm | ddd DD MMM, YYYY")}
              </span>
            </div>
            <div className={styles.row}>
              <Place className={styles.infoIcon} />
              <span className={styles.infoTxt}>{vc.event.location}</span>
            </div>
            <p className={styles.smallLblCard}>
              {t("pages.tickets.detailTickets.eventTerms")}
            </p>
            <div className={styles.contTerms}>
              <p className={styles.terms}>
                {vc.showMore === true || vc.showMore === index
                  ? vc.event.terms
                  : vc.event.terms
                  ? vc.event.terms.slice(0, 240)
                  : ""}{" "}
                <br />
                <br />-{" "}
                <em>{t("pages.tickets.detailTickets.fraventsTerms")}</em>
              </p>
              {vc.showMore === true || vc.showMore === index ? null : (
                <button
                  className={styles.viewMoreBtn}
                  onClick={() => changeVc({ showMore: index })}
                >
                  {t("pages.tickets.detailTickets.viewMore")}
                </button>
              )}
            </div>
            {renderActions(ticket, index)}
          </div>
        )
      );
    } else {
      return renderMessage();
    }
  };

  const getSaleData = async () => {
    try {
      const saleInfo = await EventsService.getSaleData(
        state?.ticketSelected?.uid
      );
      changeVc({
        saleInfo,
      });
    } catch (e) {
      console.log(t("pages.tickets.detailTickets.errorGetSell"), e);
    }
  };

  const getEvent = async () => {
    try {
      let event = {};
      if (state.eventsCache[ticketSelected?.event?.uid]) {
        event = state.eventsCache[ticketSelected?.event?.uid];
      } else {
        Model.setData("loading", true);
        event = await EventsService.getEvent(ticketSelected?.event?.uid);
        Model.setData("eventsCache", {
          ...state.eventsCache,
          [ticketSelected?.event?.uid]: event,
        });
      }

      changeVc({
        showMore: !(event?.terms?.length > 300),
        event,
      });
    } catch (e) {
      console.log(t("pages.tickets.detailTickets.errorGetEvent"), e);
    } finally {
      setTimeout(() => {
        Model.setData("loading", false);
      }, 500);
    }
  };

  const confirmRefreshCodes = async () => {
    try {
      Model.setData("loading", true);
      const response = await EventsService.updateRefreshCode(
        state?.ticketSelected?.uid
      );
      setTicketSelected((prev) => ({ ...prev, latestRefreshCode: response }));
    } catch (e) {
      console.log("Error onRefreshCodes: ", e);
    } finally {
      setTimeout(() => {
        Model.setData("loading", false);
      }, 500);
    }
  };

  const onRefreshCodes = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.detailTickets.refreshQRCodes"),
      text: t("pages.tickets.detailTickets.areOkeyRefreshCodes"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        confirmRefreshCodes();
      },
    });
  };

  React.useEffect(() => {
    if (!state.ticketSelected) {
      history.push(Routes.MY_TRANSACTIONS);
    } else {
      getEvent();
      if (isAdmin) {
        getSaleData();
      }
    }
    return () => {
      if (process.env.NODE_ENV === "production") {
        Model.setData("ticketSelected", null);
      }
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.rowTop}>
        <button className={styles.backBtn} onClick={() => history.goBack()}>
          <ChevronLeft />
        </button>
        <span className={styles.title}>Tickets</span>
      </div>

      {isAdmin ? (
        <div className={styles.rowAdditionalInfo}>
          {renderPromotor()}
          {/* <div className={styles.containerPayment}>
              <p className={styles.labelPayment}>Información de la compra:</p>
              <p
                className={styles.dataPayment}
                onClick={() =>
                  vc.saleInfo?.url
                    ? window.open(vc.saleInfo.url, "_blank")
                    : null
                }
              >
                {vc.saleInfo?.url
                  ? "Mercado pago, clic aquí para ver factura"
                  : "Información no disponible"}
              </p>
            </div> */}
        </div>
      ) : vc.event?.isCancelled ||
        ticketSelected?.status === "refunded" ? null : (
        <>
          <div className={styles.importanteText}>
            <Trans
              i18nKey="pages.tickets.detailTickets.rememberNoScreenshot"
              components={{ bold: <b /> }}
            />
          </div>
          <button className={styles.refreshQRButton} onClick={onRefreshCodes}>
            {t("pages.tickets.detailTickets.refreshQRCodes")}
          </button>
        </>
      )}
      {ticketSelected?.status === "refunded" ? (
        <div className={styles.containerRefunded}>
          <p className={styles.titleRefun}>
            {t("pages.tickets.detailTickets.refundPayment")}
          </p>
          <p className={styles.dataRefund}>
            <b>{t("pages.tickets.detailTickets.date")}:</b>{" "}
            {moment(ticketSelected?.refundedInfo?.created, "x").format(
              "DD/MM/YYYY HH:mm"
            )}
          </p>
          <p className={styles.dataRefund}>
            <b>{t("pages.tickets.detailTickets.idTransaction")}:</b>{" "}
            {ticketSelected?.refundedInfo?.id}
          </p>
          <p className={styles.dataRefund}>
            ...
            <br />
            <Trans
              i18nKey="pages.tickets.detailTickets.youCanUseRefundId"
              // eslint-disable-next-line
              components={{ bold: <b />, break: <br />, anchor: <a /> }}
            />
          </p>
        </div>
      ) : null}
      <div className={styles.rowCards}>
        {ticketSelected?.status === "payed" && !vc.event?.isCancelled
          ? renderTickets()
          : renderMessage()}
      </div>
      <div className={styles.blank} />
      <DialogForm
        show={(vc.showPrompt || vc.showResaleForm) && !state.loading}
        title={
          vc.showResaleForm
            ? t("pages.tickets.detailTickets.resellTickets")
            : t("pages.tickets.detailTickets.completeDataNewUser")
        }
        text={
          vc.showResaleForm
            ? t("pages.tickets.detailTickets.enterWantPriceResell")
            : t("pages.tickets.detailTickets.remberNoResellBefore")
        }
        leftBtText={t("commons.cancel")}
        funcLeft={() =>
          changeVc({
            showPrompt: false,
            showResaleForm: false,
            email: "",
            name: "",
            numId: "",
            lastName: "",
            priceToSell: "",
            usdPriceToSell: "",
          })
        }
        rightBtText={t("commons.accept")}
        funcRight={vc.showResaleForm ? showConfirmResale : showConfirmTransfer}
        inputs={
          vc.showResaleForm
            ? returnResellInputs()
            : [
                { label: t("commons.name"), value: "name" },
                { label: t("commons.lastName"), value: "lastName" },
                {
                  label: t("commons.email"),
                  value: "email",
                  type: "email",
                },
                { label: t("commons.numId"), value: "numId" },
              ]
        }
        vc={vc}
        changeVc={changeVc}
      />
    </div>
  );
};

export default DetailTickets;
