import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MoreVert } from "@material-ui/icons";

import { verifyEmail } from "../../utils/validations";
import DialogForm from "../../components/dialogForm";
import Avatar from "../../assets/images/avatar.jpeg";
import ShopService from "../../services/shop";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles/teamStyles";
import Model from "../../hooks/Model";

const Team = ({ history, location }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const event = location?.state?.event || {};
  const { state } = React.useContext(Store);

  const [vc, setVc] = React.useState({
    tabSelected: 0,
    reports: [],
    validators: [],
    showPrompt: false,
    email: "",
    anchorMenu: null,
    userSelected: null,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const returnText = (prop) => {
    const texts = [
      {
        title: t("pages.admin.salesTeam.teamAccessViewStats"),
        createButton: t("pages.admin.salesTeam.addSupervisor"),
        showPrompt: t("pages.admin.salesTeam.supervisorSell"),
        subtext: t("pages.admin.salesTeam.rememberAddSupervisor"),
        dataList: vc.reports,
        name: t("pages.admin.salesTeam.supervisor"),
        ref: "reports",
      },
      {
        title: t("pages.admin.salesTeam.thisTeamQrAccess"),
        createButton: t("pages.admin.salesTeam.addValidator"),
        showPrompt: t("pages.admin.salesTeam.validator"),
        subtext: t("pages.admin.salesTeam.eachUserCanScan"),
        dataList: vc.validators,
        name: t("pages.admin.salesTeam.validatorM"),
        ref: "validators",
      },
    ];

    return texts[vc.tabSelected][prop];
  };

  const openMenu = (userSelected, event) => {
    changeVc({
      anchorMenu: event.currentTarget,
      userSelected,
    });
  };

  const closeMenu = () => {
    changeVc({
      anchorMenu: null,
      userSelected: null,
    });
  };

  const sendNewUser = async (email) => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const roleRef = returnText("ref");
      const newUser = await ShopService.addUserMyTeam({
        email: email,
        role: roleRef,
      });

      changeVc({
        [roleRef]: [...vc[roleRef], newUser],
      });
      Model.updateAlerts({
        message: t("pages.admin.salesTeam.userAdded"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const deleteUser = async (uidUser) => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });
      const roleRef = returnText("ref");
      const listUsersAux = vc[roleRef];
      await ShopService.deleteUserMyTeam({
        uidUser,
        role: roleRef,
      });
      listUsersAux.splice(vc.userSelected?.index, 1);
      changeVc({
        [roleRef]: listUsersAux,
      });
      Model.updateAlerts({
        message: t("pages.admin.salesTeam.userDeleted"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onDeleteUser = () => {
    closeMenu();
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.salesTeam.confirmAction"),
      text: t("pages.admin.salesTeam.wantDeleteUser", {
        name: returnText("name"),
        email: vc.userSelected?.email,
      }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => deleteUser(vc.userSelected?.uid),
    });
  };

  const goToDetail = () => {
    closeMenu();
    if (vc.tabSelected === 2) {
      history.push(`/partnerProfile?ref=${vc.userSelected?.uid}`);
    } else {
      history.push("/detailMember", { userSelected: vc.userSelected, event });
    }
  };

  const renderUsers = () => {
    const dataList = returnText("dataList");
    if (dataList.length < 1) {
      return (
        <div className={styles.lblEmpty}>
          {t("pages.admin.salesTeam.thereNoUser")}
        </div>
      );
    }
    return dataList.map((item, index) => (
      <div className={styles.itemListUser} key={"item-list-user-" + index}>
        <div
          className={styles.imgUser}
          style={{ backgroundImage: `url(${item?.imageProfile || Avatar})` }}
        ></div>
        <div className={[styles.infoUser, styles.rowInfo].join(" ")}>
          <div className={styles.contTitleUser}>
            <p className={styles.titleUser}>{item?.name}</p>
            <p className={styles.emailUser}>{item?.email}</p>
          </div>
          <button
            onClick={(event) => openMenu({ ...item, index }, event)}
            className={styles.btnMore}
          >
            <MoreVert />
          </button>
        </div>
      </div>
    ));
  };

  const renderTabs = () =>
    [
      t("pages.admin.salesTeam.viewStats"),
      t("pages.admin.salesTeam.validateQr"),
    ].map((tab, index) => (
      <button
        className={vc.tabSelected === index ? "" : styles.disabledTab}
        onClick={() => changeVc({ tabSelected: index })}
        key={tab}
      >
        {tab}
      </button>
    ));

  const addNewUser = () => {
    try {
      const dataList = returnText("dataList");
      const emailLower = vc.email.toLowerCase().replace(/ /g, "");
      let existUser = false;

      changeVc({ email: emailLower });

      if (verifyEmail(emailLower)) {
        changeVc({ showPrompt: false });
        dataList.forEach((itemUser) => {
          if (itemUser.email === emailLower) {
            existUser = true;
          }
        });

        if (
          !existUser &&
          (vc.tabSelected === 0 ? emailLower !== state?.userInfo?.email : true)
        ) {
          Model.setData("dialog", {
            open: true,
            title: t("pages.admin.salesTeam.confirmAction"),
            text: t("pages.admin.salesTeam.wantAddUser", {
              name: returnText("name"),
              email: emailLower,
            }),
            txtLeft: t("commons.cancel"),
            fnLeft: () => Model.setData("dialog", { open: false }),
            txtRight: t("commons.yesConfirm"),
            fnRight: () => sendNewUser(emailLower),
          });
        } else {
          throw new Error(t("pages.admin.salesTeam.userAlreadyBe"));
        }
      } else {
        throw new Error(t("pages.admin.salesTeam.enterValidEmail"));
      }
    } catch (e) {
      Model.updateAlerts({
        message: String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const getMyTeam = async () => {
    try {
      Model.setData("loading", !state.teamShop);

      if (state.teamShop) {
        changeVc(state.teamShop);
      }

      const dataTeam = await ShopService.getMyTeam();
      const teamShop = {
        validators: dataTeam?.validators || [],
        reports: dataTeam?.reports || [],
      };
      changeVc(teamShop);
      Model.setData("teamShop", teamShop);
    } catch (e) {
      console.log(t("pages.admin.salesTeam.errorGetTeam"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    getMyTeam();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.rowTabs}>{renderTabs()}</div>
      <p className={styles.title}>{returnText("title")}</p>
      <button
        className={styles.createButton}
        onClick={() => changeVc({ showPrompt: true, email: "" })}
      >
        {returnText("createButton")}
      </button>
      <div className={styles.gridUsers}>{renderUsers()}</div>
      <Menu
        anchorEl={vc.anchorMenu}
        open={Boolean(vc.anchorMenu)}
        onClose={closeMenu}
        PaperProps={{ style: { width: 200 } }}
      >
        <MenuItem onClick={goToDetail}>
          {t("pages.admin.salesTeam.viewDetails")}
        </MenuItem>
        <MenuItem onClick={onDeleteUser}>
          {t("pages.admin.salesTeam.delete")}
        </MenuItem>
      </Menu>
      <div className={styles.blank} />
      <DialogForm
        show={vc.showPrompt && !state.loading}
        title={`${t("pages.admin.salesTeam.add")} ${returnText("showPrompt")}`}
        text={t("pages.admin.salesTeam.pleaseEnterCompleteEmail")}
        subtext={returnText("subtext")}
        leftBtText={t("commons.cancel")}
        funcLeft={() => changeVc({ showPrompt: false, email: "" })}
        rightBtText={t("commons.accept")}
        funcRight={addNewUser}
        inputs={[
          {
            label: t("commons.email"),
            value: "email",
            type: "email",
          },
        ]}
        vc={vc}
        changeVc={changeVc}
      />
    </>
  );
};

export default Team;
