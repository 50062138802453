import React from "react";
import {
  CloudDownload,
  CheckCircle,
  AttachMoney,
  AccessTime,
  Block,
  Add,
  VerifiedUser,
  Edit,
  Delete,
  Visibility,
  Refresh,
  LocalShipping,
  ShoppingCart,
  Forward,
} from "@material-ui/icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
  BarElement,
} from "chart.js";
import {
  Menu,
  MenuItem,
  ListItemText,
  Grid,
  Switch,
  Tooltip as TooltipMui,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

import {
  currencyFormat,
  renderShortPrice,
  totalSellReducer,
  totalQuantityReducer,
  returnLabelSellStatus,
} from "../../utils/validations";
import Avatar from "../../assets/images/avatar.jpeg";
import DialogForm from "../../components/dialogForm";
import { Store } from "../../hooks/main_store";
import ShopService from "../../services/shop";
import pageStyles from "./styles/salesStyles";
import Model from "../../hooks/Model";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
  BarElement
);

const SalesShop = ({ history, refPartner, onError }) => {
  const { t, i18n } = useTranslation();
  const { state } = React.useContext(Store);
  const valueYears = [2024, 2023, 2022];
  const yearLabels = [
    t("commons.january"),
    t("commons.february"),
    t("commons.march"),
    t("commons.april"),
    t("commons.may"),
    t("commons.june"),
    t("commons.july"),
    t("commons.august"),
    t("commons.september"),
    t("commons.october"),
    t("commons.november"),
    t("commons.december"),
  ];
  const initialValuesPromoCode = {
    textPromoCode: "",
    quantityPromoCode: "",
    userLimitPromoCode: "",
    datePromoCode: "",
    valuePromoCode: "",
    percentPromoCode: "",
  };
  const monthLabels = new Array(31).fill(1).map((_, index) => index + 1);

  const styles = pageStyles();

  const [selectedSent, setSelectedSent] = React.useState([]);
  const [selectedWaiting, setSelectedWaiting] = React.useState([]);
  const [anchorFilter, setAnchorFilter] = React.useState(null);
  const [anchorYear, setAnchorYear] = React.useState(null);
  const [sizeList, setSizeList] = React.useState(20);
  const [vc, setVc] = React.useState({
    allSells: [],
    historyDate: "month",
    sells2render: [],
    textSearch: "",
    selectedFilter: "",
    promoCodes: [],
    showPromptPromoCode: false,
    editPromoCode: false,
    historyPromoCode: [],
    indexDetailPromoCode: -1,
    showListPromoCode: true,
    selectedYear: valueYears[0],
    partnerInfo: {},
    ...initialValuesPromoCode,
  });
  const changeVc = (dataVc) => setVc((ov) => ({ ...ov, ...dataVc }));

  const changeState = (name, event) => {
    event.persist();
    changeVc({
      [name]: event.target.value,
    });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      filterData();
    } else if (event.keyCode === 27) {
      changeVc({ textSearch: "" });
      changeVc({ sells2render: vc.allSells });
    }
  };

  const changePromoCodeValue = (data) => {
    const currentData = { ...data };
    const keyValue = Object.keys(data)[0];
    currentData[keyValue] = currentData[keyValue].replace(/ /g, "");

    switch (keyValue) {
      case "textPromoCode":
        currentData[keyValue] = currentData[keyValue].replace(
          /[^a-zA-Z0-9]/g,
          ""
        );
        break;
      case "quantityPromoCode":
        currentData[keyValue] = currentData[keyValue].replace(/[^0-9]/g, "");
        break;
      case "userLimitPromoCode":
        currentData[keyValue] = currentData[keyValue].replace(/[^0-9]/g, "");
        break;
      case "valuePromoCode":
        if (!currentData[keyValue]) {
          currentData[keyValue] = "";
        } else {
          currentData[keyValue] = String(
            parseInt(currentData[keyValue].replace(/[^0-9]/g, ""))
          );
        }
        break;
      case "percentPromoCode":
        if (!currentData[keyValue]) {
          currentData[keyValue] = "";
        } else {
          currentData[keyValue] = String(
            parseInt(currentData[keyValue].replace(/[^0-9]/g, ""))
          );
          currentData[keyValue] =
            parseInt(currentData[keyValue]) > 100
              ? 100
              : parseInt(currentData[keyValue]) <= 0
              ? 0
              : currentData[keyValue];
        }
        break;
      default:
        break;
    }

    changeVc(currentData);
  };

  const returnHistorySales = (data2render = []) => {
    const sellsByDate = new Array(vc.historyDate === "year" ? 12 : 31).fill(0);
    (data2render?.length > 0 ? data2render : vc.allSells)
      .filter((sell) => sell.status !== "pending" && sell.status !== "rejected")
      .forEach((sell) => {
        const numMonth = parseInt(moment(sell.created, "x").format("M")) - 1;
        const numDay = parseInt(moment(sell.created, "x").format("D")) - 1;
        const numYear = parseInt(moment(sell.created, "x").format("YYYY"));

        if (vc.historyDate === "year" && numYear === vc.selectedYear) {
          sellsByDate[numMonth] = (sellsByDate[numMonth] || 0) + 1;
        } else if (vc.historyDate === "month") {
          const currentMonth = parseInt(moment().format("M")) - 1;
          if (currentMonth === numMonth) {
            sellsByDate[numDay] = (sellsByDate[numDay] || 0) + 1;
          }
        }
      });
    return sellsByDate;
  };

  const returnDataTypes = () =>
    vc.allSells.filter(
      (sell) => sell.status !== "pending" && sell.status !== "rejected"
    ).length;

  const returnStatusSales = () => {
    const labelsStatus = [
      t("pages.admin.salesShop.forSend"),
      t("pages.admin.salesShop.pending"),
      t("pages.admin.salesShop.rejected"),
      t("pages.admin.salesShop.sent"),
      t("pages.admin.salesShop.delivered"),
    ];
    const randColor = () => Math.floor(Math.random() * 256);
    const colors = labelsStatus.map(
      () => `${randColor()}, ${randColor()}, ${randColor()}`
    );
    const dataStatus = new Array(labelsStatus.length).fill(0);

    vc.allSells.forEach((sell) => {
      if (sell.status === "payed") {
        dataStatus[0] = (dataStatus[0] || 0) + 1;
      } else if (sell.status === "pending") {
        dataStatus[1] = (dataStatus[1] || 0) + 1;
      } else if (sell.status === "rejected") {
        dataStatus[2] = (dataStatus[2] || 0) + 1;
      } else if (sell.status === "approved") {
        dataStatus[3] = (dataStatus[3] || 0) + 1;
      } else if (sell.status === "finished") {
        dataStatus[4] = (dataStatus[4] || 0) + 1;
      }
    });

    return {
      labels: labelsStatus,
      datasets: [
        {
          data: dataStatus,
          backgroundColor: colors.map((color) => `rgba(${color}, 0.2)`),
          borderColor: colors.map((color) => `rgba(${color}, 1)`),
          borderWidth: 1,
        },
      ],
    };
  };

  const returnDataIncome = () => {
    const reduceSells = vc.allSells
      .filter((sell) => sell.status !== "rejected" && sell.status !== "pending")
      .map((sell) => totalSellReducer(sell.products));
    return reduceSells?.length > 0
      ? reduceSells.reduce((total, current) => total + current)
      : 0;
  };

  const renderLabelFilter = () => {
    switch (vc.selectedFilter) {
      case "all":
        return t("pages.admin.salesShop.filter");
      case "payed":
        return t("pages.admin.salesShop.comple");
      case "pending":
        return t("pages.admin.salesShop.pendi");
      case "approved":
        return t("pages.admin.salesShop.sen");
      case "finished":
        return t("pages.admin.salesShop.deliv");
      case "rejected":
        return t("pages.admin.salesShop.rejec");
      default:
        return t("pages.admin.salesShop.filter");
    }
  };

  const changeYearRef = (value) => {
    setAnchorYear(null);
    changeVc({ selectedYear: value });
  };

  const filterData = (type) => {
    setAnchorFilter(null);
    if (!type) {
      // Press on search button
      if (vc.textSearch) {
        changeVc({
          sells2render: vc.allSells.filter((sell) => {
            const flagName = String(sell?.client?.name)
              .toLowerCase()
              .includes(vc.textSearch.toLowerCase());
            const flagPhone = String(sell?.client?.phone)
              .toLowerCase()
              .includes(vc.textSearch.toLowerCase());
            const flagEmail = String(sell?.client?.email)
              .toLowerCase()
              .includes(vc.textSearch.toLowerCase());
            return flagName || flagPhone || flagEmail;
          }),
        });
      } else {
        changeVc({ sells2render: vc.allSells });
      }
    } else {
      const status = type === "all" ? "" : type;
      changeVc({
        sells2render: vc.allSells.filter((sell) => {
          const hasStatus = String(sell?.status)
            .toLowerCase()
            .includes(status.toLowerCase());

          return hasStatus;
        }),
        selectedFilter: status,
      });
    }
  };

  const returnLabelStatusColor = (status = "") => {
    switch (status) {
      case "payed":
        return styles.orangeColor;
      case "pending":
        return styles.yellowColor;
      case "approved":
        return styles.blueColor;
      case "finished":
        return styles.greenColor;
      default:
        return styles.redColor;
    }
  };

  const renderListSales = (data2render = []) => {
    const finalData =
      data2render?.length > 0
        ? data2render.sort((a, b) => b.created - a.created)
        : vc.sells2render
            .sort((a, b) => b.created - a.created)
            .slice(0, sizeList);

    if (finalData.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.salesShop.noMoreSells")}
        </p>
      );
    }
    return finalData.map((sell, index) => (
      <div
        key={"item-sale-" + index}
        className={styles.itemSale}
        onClick={() => {
          Model.setData("sellSelected", sell);
          history.push("/sellDetail", {
            sellSelected: sell,
            isAdminView: true,
          });
        }}
      >
        <div className={styles.nameItemSale}>
          {sell?.client?.name || "-"}
          <br />
          <span>
            {sell?.client?.email || t("pages.admin.salesShop.withoutEmail")}
          </span>
        </div>
        <div className={styles.ticketsItemSale}>
          {totalQuantityReducer(sell?.products)}{" "}
          {t("pages.admin.salesShop.product")}
          {totalQuantityReducer(sell?.products) === 1 ? "" : "s"}
          <br /> <span>{sell?.tickets?.[0]?.label}</span>
        </div>
        <div className={styles.dateItemSale}>
          <b>
            <span className={returnLabelStatusColor(sell?.status)}>
              {returnLabelSellStatus(sell?.status)}
            </span>
          </b>
          <br />
          {moment(sell.created, "x").format("YYYY-MM-DD HH:mm")}
        </div>
      </div>
    ));
  };

  const onChangeSellStatus = async (status = "") => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const data2send = {
        payed: { sells: selectedSent, status: "payed" },
        approved: { sells: selectedWaiting, status: "approved" },
        finished: { sells: selectedSent, status: "finished" },
      };

      const dataSells = await ShopService.onChangeSellStatus({
        ...data2send[status],
        partner: refPartner,
      });
      const data2save = {
        allSells: dataSells || [],
        sells2render: dataSells || [],
      };
      changeVc(data2save);
      Model.setData("sellsShopPartner", dataSells);
      setSelectedSent([]);
      setSelectedWaiting([]);
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.salesShop.errorUpdateSellStatus") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSent2Finished = () => {
    if (selectedSent.length > 0) {
      Model.setData("dialog", {
        open: true,
        title: t("pages.admin.salesShop.confirmDelivery"),
        text: t("pages.admin.salesShop.areYourSureChangeStatus", {
          length: selectedSent.length,
          multiple: selectedSent.length === 1 ? "" : "s",
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: () => onChangeSellStatus("finished"),
      });
    }
  };

  const onReturnSent = () => {
    if (selectedSent.length > 0) {
      Model.setData("dialog", {
        open: true,
        title: t("pages.admin.salesShop.confirmReturnDelivery"),
        text: t("pages.admin.salesShop.areYourSureChangeReturnStatus", {
          length: selectedSent.length,
          multiple: selectedSent.length === 1 ? "" : "s",
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: () => onChangeSellStatus("payed"),
      });
    }
  };

  const onWaiting2Sent = () => {
    if (selectedWaiting.length > 0) {
      Model.setData("dialog", {
        open: true,
        title: t("pages.admin.salesShop.confirmSend"),
        text: t("pages.admin.salesShop.areYourSureChangeSendStatus", {
          length: selectedWaiting.length,
          multiple: selectedWaiting.length === 1 ? "" : "s",
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.yesConfirm"),
        fnRight: () => onChangeSellStatus("approved"),
      });
    }
  };

  const renderWaiting = () => {
    const waiting2render = vc.allSells.filter(
      (sell) => sell?.status === "payed"
    );
    if (waiting2render.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.salesShop.thereNoItems")}
        </p>
      );
    }
    return waiting2render.map((sell, index) => (
      <div
        key={"item-waiting-" + index}
        className={[
          styles.itemSale,
          selectedWaiting.indexOf(sell?.uid) > -1 ? styles.orangeBg : "",
        ].join(" ")}
      >
        <div className={styles.nameItemSale}>
          {sell?.client?.name || "-"}
          <br />
          <span>
            {sell?.client?.email || t("pages.admin.salesShop.withoutEmail")}
          </span>
        </div>
        <div className={styles.ticketsItemSale}>
          {totalQuantityReducer(sell?.products)}{" "}
          {t("pages.admin.salesShop.product")}
          {totalQuantityReducer(sell?.products) === 1 ? "" : "s"}
          <br />{" "}
          <span>
            {t("pages.admin.salesShop.bought")}
            {totalQuantityReducer(sell?.products) === 1 ||
            i18n.language === "en"
              ? ""
              : "s"}
          </span>
        </div>
        <div className={styles.dateItemSale}>
          {currencyFormat(totalSellReducer(sell?.products), "COP", 0)}
          <br /> <span>{t("pages.admin.salesShop.totalSells")}</span>
        </div>
        <button
          className={styles.viewItemSale}
          onClick={() => {
            Model.setData("sellSelected", sell);
            history.push("/sellDetail", {
              sellSelected: sell,
              isAdminView: true,
            });
          }}
        >
          {t("pages.admin.salesShop.detail")}
        </button>
        <button
          className={[
            styles.selectItemSale,
            selectedWaiting.indexOf(sell?.uid) > -1 ? "selected" : "",
          ].join(" ")}
          onClick={() => {
            setSelectedSent([]);
            setSelectedWaiting((ov) => {
              let newData = [...ov];
              const index = newData.indexOf(sell?.uid);

              if (index > -1) {
                newData.splice(index, 1);
                return newData;
              }

              return [...newData, sell?.uid];
            });
          }}
        >
          <div />
        </button>
      </div>
    ));
  };

  const renderQuickStats = (typeData = "sales") => {
    const cards =
      typeData === "sales"
        ? [
            {
              label: t("pages.admin.salesShop.totalIncomes"),
              icon: <AttachMoney />,
              value: renderShortPrice(returnDataIncome(), true),
              longData: returnDataIncome(),
            },
            {
              label: t("pages.admin.salesShop.selledProducts"),
              icon: <ShoppingCart />,
              value: renderShortPrice(returnDataTypes(), true),
              longData: returnDataTypes(),
            },
            {
              label: t("pages.admin.salesShop.sellsWaiting"),
              icon: <AccessTime />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[0],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[0],
            },
            {
              label: t("pages.admin.salesShop.senta"),
              icon: <LocalShipping />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[3],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[3],
            },
            {
              label: t("pages.admin.salesShop.finishedDelivery"),
              icon: <VerifiedUser />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[4],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[4],
            },
          ]
        : [
            {
              label: t("pages.admin.salesShop.completeSells"),
              icon: <CheckCircle />,
              value: renderShortPrice(returnDataTypes(), true),
              longData: returnDataTypes(),
            },
            {
              label: t("pages.admin.salesShop.pendingSells"),
              icon: <AccessTime />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[1],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[1],
            },
            {
              label: t("pages.admin.salesShop.rejectedSells"),
              icon: <Block />,
              value: renderShortPrice(
                returnStatusSales()?.datasets?.[0]?.data?.[2],
                true
              ),
              longData: returnStatusSales()?.datasets?.[0]?.data?.[2],
            },
          ];

    return cards.map((info) => {
      const longdata = String(info.longData).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return (
        <TooltipMui key={info.label} title={`${info.label}: ${longdata}`}>
          <div className={styles.cardQuickStat}>
            {info.icon}
            <p>{longdata}</p>
            {/* <p>{info.value}</p> */}
            <span>{info.label}</span>
          </div>
        </TooltipMui>
      );
    });
  };

  const onDeletePromoCode = async (textCode) => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      await ShopService.deletePromoCode({
        textPromoCode: textCode,
      });

      Model.updateAlerts({
        message: t("pages.admin.salesShop.deletedCode"),
        variant: "success",
      });

      changeVc({
        ...initialValuesPromoCode,
        showPromptPromoCode: false,
        editPromoCode: false,
      });

      getPromoCodes();
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.salesShop.errorPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const showDialogDeletePromoCode = (textCode) => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.salesShop.deletePromoCode"),
      text: t("pages.admin.salesShop.areYourSureDeletePromoCode", { textCode }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: () => onDeletePromoCode(textCode),
    });
  };

  const onDetailPromoCode = async (promoCode, index) => {
    try {
      changeVc({
        indexDetailPromoCode: -1,
      });
      Model.setData("loading", true);
      if (!promoCode) {
        throw new Error(t("pages.admin.salesShop.noExistCode"));
      }
      const historyPromoCode = await ShopService.getHistoryPromoCode({
        textPromoCode: promoCode,
      });

      changeVc({
        historyPromoCode: historyPromoCode || [],
        indexDetailPromoCode: index,
      });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.salesShop.errorPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const renderPromoCodes = () => {
    if (vc.promoCodes.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.salesShop.noCodesToShow")}
        </p>
      );
    }
    return vc.promoCodes.map((infoCode, index) => (
      <React.Fragment key={"item-promoCode-" + index}>
        <div
          className={[styles.itemSale, styles.itemPromoCode].join(" ")}
          onClick={() => changeVc({ indexDetailPromoCode: -1 })}
        >
          <div className={styles.nameItemSale}>
            {infoCode?.textPromoCode || "-"}
            <br />
            <span>
              {t("pages.admin.salesShop.discount")}:{" "}
              {!!infoCode?.valuePromoCode ? "$" : ""}
              {renderShortPrice(
                infoCode?.valuePromoCode || infoCode?.percentPromoCode
              )}{" "}
              {!!infoCode?.valuePromoCode ? "COP" : "%"}
            </span>
          </div>
          <div className={styles.ticketsItemSale}>
            {infoCode?.quantityPromoCode || 0}{" "}
            {t("pages.admin.salesShop.created")}
            <br />{" "}
            <span>
              {infoCode?.userLimitPromoCode || 0}{" "}
              {t("pages.admin.salesShop.byUser")}
            </span>
          </div>
          <div className={styles.dateItemPromoCode}>
            {t("pages.admin.salesShop.expDate")}:
            <br />
            {moment(infoCode?.datePromoCode).format("ddd DD MMM YYYY, hh:mm a")}
            <br />
            {/* <span>Venta total</span> */}
          </div>
          <div className={styles.actionsItemSale}>
            <TooltipMui title={t("pages.admin.salesShop.viewDetails")}>
              <button
                onClick={() =>
                  onDetailPromoCode(infoCode?.textPromoCode, index)
                }
              >
                <Visibility />
              </button>
            </TooltipMui>
            {refPartner ? null : (
              <>
                <TooltipMui title={t("pages.admin.salesShop.edit")}>
                  <button
                    onClick={() => {
                      changeVc({
                        showPromptPromoCode: true,
                        textPromoCode: infoCode?.textPromoCode,
                        quantityPromoCode: infoCode?.quantityPromoCode,
                        userLimitPromoCode: infoCode?.userLimitPromoCode,
                        datePromoCode: infoCode?.datePromoCode,
                        valuePromoCode: infoCode?.valuePromoCode,
                        percentPromoCode: infoCode?.percentPromoCode,
                        editPromoCode: true,
                      });
                    }}
                  >
                    <Edit />
                  </button>
                </TooltipMui>
                <TooltipMui title={t("pages.admin.salesShop.delete")}>
                  <button
                    onClick={() =>
                      showDialogDeletePromoCode(infoCode?.textPromoCode)
                    }
                  >
                    <Delete />
                  </button>
                </TooltipMui>
              </>
            )}
          </div>
        </div>
        <div
          className={[
            styles.detailsContainerPromoCode,
            vc.indexDetailPromoCode === index ? styles.openDetails : "",
          ].join(" ")}
        >
          <div
            className={[
              styles.dataDetailsPromoCode,
              !vc.showListPromoCode ? styles.hideMobileContainer : "",
            ].join(" ")}
          >
            <button
              className={styles.changeViewDetailPromoCode}
              onClick={() => changeVc({ showListPromoCode: false })}
            >
              {t("pages.admin.salesShop.viewGraphs")}
            </button>
            <>
              <h5>{t("pages.admin.salesShop.data")}</h5>
              <div className="rowData">
                <p>
                  {t("pages.admin.salesShop.used")}:{" "}
                  {vc.historyPromoCode?.length || 0}
                </p>
                <p>
                  {t("pages.admin.salesShop.remaning")}:{" "}
                  {parseInt(infoCode?.quantityPromoCode) -
                    (vc.historyPromoCode?.length || 0)}
                </p>
              </div>
              <div className={styles.listDetailsPromoCode}>
                {renderListSales(vc.historyPromoCode)}
              </div>
            </>
          </div>
          <div
            className={[
              styles.graphDetailsPromoCode,
              vc.showListPromoCode ? styles.hideMobileContainer : "",
            ].join(" ")}
          >
            <button
              className={styles.changeViewDetailPromoCode}
              onClick={() => changeVc({ showListPromoCode: true })}
            >
              {t("pages.admin.salesShop.viewList")}
            </button>
            {vc.indexDetailPromoCode === index && (
              <Line
                className={styles.responsiveMobileContainer}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: t("pages.admin.salesShop.historyUses", {
                        date:
                          vc.historyDate === "year"
                            ? t("pages.admin.salesShop.year")
                            : t("pages.admin.salesShop.lastMonth"),
                      }),
                    },
                  },
                  scales: {
                    yAxis: { beginAtZero: true, ticks: { stepSize: 1 } },
                  },
                }}
                data={{
                  labels: vc.historyDate === "year" ? yearLabels : monthLabels,
                  datasets: [
                    {
                      data: returnHistorySales(vc.historyPromoCode),
                      borderColor: "rgb(255, 99, 132)",
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                      yAxisID: "yAxis",
                    },
                  ],
                }}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    ));
  };

  const showPromoCodeForm = () => {
    changeVc({
      showPromptPromoCode: true,
      editPromoCode: false,
      ...initialValuesPromoCode,
    });
  };

  const onSendPromoCode = async () => {
    try {
      Model.setData("loading", true);
      if (
        !vc.textPromoCode ||
        !vc.quantityPromoCode ||
        !vc.userLimitPromoCode ||
        !vc.datePromoCode ||
        !(vc.valuePromoCode || vc.percentPromoCode)
      ) {
        throw new Error(t("pages.admin.salesShop.fillDataYet"));
      }

      changeVc({
        ...initialValuesPromoCode,
        showPromptPromoCode: false,
        editPromoCode: false,
      });

      await ShopService.createPromoCode({
        textPromoCode: vc.textPromoCode,
        quantityPromoCode: vc.quantityPromoCode,
        userLimitPromoCode: vc.userLimitPromoCode,
        datePromoCode: vc.datePromoCode,
        valuePromoCode: vc.valuePromoCode,
        percentPromoCode: vc.percentPromoCode,
        editPromoCode: vc.editPromoCode,
      });

      Model.updateAlerts({
        message: vc.editPromoCode
          ? t("pages.admin.salesShop.editedCode")
          : t("pages.admin.salesShop.createdCode"),
        variant: "success",
      });

      getPromoCodes();
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.salesShop.errorPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const returnInputsPromoCode = () => {
    const inputs = [
      {
        label: t("pages.admin.salesShop.text"),
        value: "textPromoCode",
        disabled: vc.editPromoCode,
      },
      {
        label: t("pages.admin.salesShop.quantity"),
        value: "quantityPromoCode",
      },
      {
        label: t("pages.admin.salesShop.limitUseByClient"),
        value: "userLimitPromoCode",
      },
      {
        label: t("pages.admin.salesShop.validUntil"),
        value: "datePromoCode",
        type: "datetime-local",
        disabled: false,
      },
      {
        label: t("pages.admin.salesShop.priceCop"),
        value: "valuePromoCode",
        disabled: !!vc.percentPromoCode && !vc.valuePromoCode,
      },
      {
        label: t("pages.admin.salesShop.percentage"),
        value: "percentPromoCode",
        disabled: !!vc.valuePromoCode && !vc.percentPromoCode,
      },
    ];

    return inputs;
  };

  const rageDateHandleChange = (event) => {
    changeVc({
      historyDate: event.target.checked ? "year" : "month",
    });
  };

  const renderSents = () => {
    const sents2render = vc.allSells.filter(
      (sell) => sell?.status === "approved"
    );
    if (sents2render.length < 1) {
      return (
        <p className={styles.labelEmpty}>
          {t("pages.admin.salesShop.thereNoItems")}
        </p>
      );
    }
    return sents2render.map((sell, index) => (
      <div
        key={"item-sent-" + index}
        className={[
          styles.itemSale,
          selectedSent.indexOf(sell?.uid) > -1 ? styles.blueBg : "",
        ].join(" ")}
      >
        <div className={styles.nameItemSale}>
          {sell?.client?.name || "-"}
          <br />
          <span>
            {sell?.client?.email || t("pages.admin.salesShop.withoutEmail")}
          </span>
        </div>
        <div className={styles.ticketsItemSale}>
          {totalQuantityReducer(sell?.products)}{" "}
          {t("pages.admin.salesShop.product")}
          {totalQuantityReducer(sell?.products) === 1 ? "" : "s"}
          <br />{" "}
          <span>
            {t("pages.admin.salesShop.bought")}
            {totalQuantityReducer(sell?.products) === 1 ? "" : "s"}
          </span>
        </div>
        <div className={styles.dateItemSale}>
          {currencyFormat(totalSellReducer(sell?.products), "COP", 0)}
          <br /> <span>{t("pages.admin.salesShop.totalSells")}</span>
        </div>

        <button
          className={styles.viewItemSale}
          onClick={() => {
            Model.setData("sellSelected", sell);
            history.push("/sellDetail", {
              sellSelected: sell,
              isAdminView: true,
            });
          }}
        >
          {t("pages.admin.salesShop.detail")}
        </button>
        <button
          className={[
            styles.selectItemSale,
            selectedSent.indexOf(sell?.uid) > -1 ? "selected" : "",
          ].join(" ")}
          onClick={() => {
            setSelectedWaiting([]);
            setSelectedSent((ov) => {
              let newData = [...ov];
              const index = newData.indexOf(sell?.uid);

              if (index > -1) {
                newData.splice(index, 1);
                return newData;
              }

              return [...newData, sell?.uid];
            });
          }}
        >
          <div />
        </button>
      </div>
    ));
  };

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const returnDataExport = () => {
    let dataExport = [
      [
        t("pages.admin.salesShop.name"),
        t("pages.admin.salesShop.email"),
        t("pages.admin.salesShop.phone"),
        t("pages.admin.salesShop.productM"),
        t("pages.admin.salesShop.type"),
        t("pages.admin.salesShop.numQuantity"),
        t("pages.admin.salesShop.sellDate"),
        t("pages.admin.salesShop.status"),
        t("pages.admin.salesShop.promoCode"),
        t("pages.admin.salesShop.totalValueCop"),
      ],
    ];
    vc.allSells.forEach((sell) => {
      sell.products.forEach((product) => {
        dataExport.push([
          sell?.client?.name,
          sell?.client?.email,
          sell?.client?.phone,
          product?.name,
          product?.type,
          parseInt(product?.quantity),
          moment(sell?.created, "x").format("YYYY-MM-DD HH:mm"),
          returnLabelSellStatus(sell?.status),
          sell?.promoCode,
          currencyFormat(
            parseFloat(product?.price) * parseInt(product?.quantity),
            "COP",
            0
          ),
        ]);
      });
    });
    return dataExport;
  };

  const exportData = () => {
    Model.setData("dialog", { open: false });
    const wb = XLSX.utils.book_new();
    const dateNow = moment().format("MM/DD/YYYY");
    const secondNow = moment().format("x");
    wb.Props = {
      Title: t("pages.admin.salesShop.fileNameExport", { dateNow }),
      Subject: t("pages.admin.salesShop.sellFraventsShop"),
      Author: state?.userInfo?.name,
      CreatedDate: new Date(),
    };
    wb.SheetNames.push(t("pages.admin.salesShop.sells"));
    wb.Sheets[t("pages.admin.salesShop.sells")] = XLSX.utils.aoa_to_sheet(
      returnDataExport()
    );
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `fravents_shop_sales_${secondNow}.xlsx`
    );
  };

  const showAlertDownload = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.salesShop.alertDownload"),
      text: t("pages.admin.salesShop.toDownloadInfoAccept"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: exportData,
    });
  };

  const getPromoCodes = async (isLoading = true) => {
    try {
      Model.setData("loading", isLoading);
      const dataPromoCodes = await ShopService.getPromoCodes({
        partner: refPartner,
      });
      let dataArray = [];
      if (dataPromoCodes) {
        dataArray = Object.keys(dataPromoCodes).map((key) => ({
          ...dataPromoCodes[key],
          textPromoCode: key,
        }));
      }
      changeVc({ promoCodes: dataArray });
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.salesShop.errorGetPromoCode") + String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const getMySales = async (callback) => {
    const isLoading = !(state.sellsShopPartner?.length > 0);
    try {
      Model.setData("loading", isLoading);
      const dataSells = await ShopService.getMySells({ partner: refPartner });
      const data2save = {
        allSells: (refPartner ? dataSells?.sells : dataSells) || [],
        sells2render: (refPartner ? dataSells?.sells : dataSells) || [],
      };
      changeVc(data2save);
      Model.setData("sellsShopPartner", dataSells);

      if (refPartner) {
        changeVc({ partnerInfo: dataSells?.partnerInfo || {} });
      }
    } catch (e) {
      Model.updateAlerts({
        message: t("pages.admin.salesShop.errorGetSells") + String(e),
        variant: "error",
      });

      if (refPartner && onError) {
        onError();
      }
    } finally {
      Model.setData("loading", false);
      if (callback === "getPromoCodes") {
        getPromoCodes(isLoading);
      }
    }
  };

  React.useEffect(() => {
    getMySales("getPromoCodes");
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.columnGray}>
        <div className={[styles.gridCards, styles.lessMarginTop].join(" ")}>
          <p className={styles.title}>
            {t("pages.admin.salesShop.manageSellsSends")}
          </p>

          <div
            className={[styles.cardGraph, styles.noHeight].join(" ")}
            style={{ gridArea: "refered" }}
          >
            <p className={styles.titleCardGraph}>
              {t("pages.admin.salesShop.onWaiting")}
            </p>
            <p className={styles.bodyCardGraph}>
              {t("pages.admin.salesShop.reviewAndSelect")}
            </p>
            <div className={styles.contListCard}>{renderWaiting()}</div>
            <button
              className={[
                styles.move2rightButton,
                selectedWaiting.length > 0
                  ? "active"
                  : selectedSent.length > 0
                  ? "active-rev"
                  : "",
              ].join(" ")}
              onClick={selectedSent.length > 0 ? onReturnSent : onWaiting2Sent}
            >
              <Forward />
              {selectedSent.length > 0 ? (
                <span className="label">
                  {t("pages.admin.salesShop.return")} ({selectedSent.length})
                </span>
              ) : (
                <span className="label">
                  {t("pages.admin.salesShop.send")} ({selectedWaiting.length})
                </span>
              )}
            </button>
          </div>
          <div
            className={[styles.cardGraph, styles.noHeight].join(" ")}
            style={{ gridArea: "logistic" }}
          >
            <p className={styles.titleCardGraph}>
              {t("pages.admin.salesShop.sent")}
            </p>
            <p className={styles.bodyCardGraph}>
              {t("pages.admin.salesShop.sendUpdatesConfirm")}
            </p>
            <div className={styles.contListCard}>{renderSents()}</div>
            <button
              className={[
                styles.move2rightButton,
                selectedSent.length > 0 ? "active" : "",
              ].join(" ")}
              onClick={onSent2Finished}
            >
              <CheckCircle />
              <span className="label">
                {t("pages.admin.salesShop.delivery")} ({selectedSent.length})
              </span>
            </button>
          </div>
        </div>
        <div className={[styles.row, styles.mobileMarginBotton].join(" ")}>
          <p className={styles.title}>
            {t("pages.admin.salesShop.sellDataOf")}{" "}
            {refPartner
              ? vc.partnerInfo?.name || t("pages.admin.salesShop.eCommerce")
              : t("pages.admin.salesShop.yourEcommerce")}
          </p>
          {refPartner ? (
            <button
              onClick={() =>
                history.push(`/partnerProfile?ref=${refPartner}&tab=1`)
              }
              className={styles.visitPartner}
            >
              <img
                alt="partner"
                src={vc.partnerInfo?.logo || Avatar}
                className={styles.logoPartner}
              />
              <span>{t("pages.admin.salesShop.viewShop")}</span>
            </button>
          ) : null}
          {refPartner ? null : (
            <button
              className={styles.downloadButton}
              onClick={showAlertDownload}
            >
              <CloudDownload />
              {t("pages.admin.salesShop.downloadData")}
              <div className={styles.sublabelButton}>
                {t("pages.admin.salesShop.maxMb")}
              </div>
            </button>
          )}
        </div>
        <div className={[styles.row, styles.rowQuickStats].join(" ")}>
          {renderQuickStats()}
        </div>
        <div className={styles.gridCards}>
          <div className={styles.cardGraph} style={{ gridArea: "line" }}>
            <Grid
              container
              className={[styles.row, styles.rowRangeDateSwitch].join(" ")}
            >
              <Grid item>{t("pages.admin.salesShop.lastMonth")}</Grid>
              <Grid item>
                <Switch
                  checked={vc.historyDate === "year"}
                  onChange={rageDateHandleChange}
                  className={styles.switchContainer}
                />
              </Grid>
              <Grid item>
                <button
                  className={styles.yearButton}
                  onClick={(event) => {
                    setAnchorYear(event.currentTarget);
                  }}
                >
                  {t("pages.admin.salesShop.year")} {vc.selectedYear}
                </button>
                <Menu
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  anchorEl={anchorYear}
                  open={Boolean(anchorYear)}
                  onClose={() => setAnchorYear(null)}
                >
                  {valueYears.map((value) => (
                    <MenuItem key={value} onClick={() => changeYearRef(value)}>
                      <ListItemText primary={value} />
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: t("pages.admin.salesShop.historySells", {
                      historyDate:
                        vc.historyDate === "month"
                          ? yearLabels[parseInt(moment().format("M")) - 1]
                          : vc.selectedYear,
                    }),
                  },
                },
                scales: {
                  yAxis: {
                    beginAtZero: true,
                    ticks: { stepSize: 1 },
                  },
                },
              }}
              data={{
                labels: vc.historyDate === "year" ? yearLabels : monthLabels,
                datasets: [
                  {
                    data: returnHistorySales(),
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    yAxisID: "yAxis",
                  },
                ],
              }}
            />
          </div>
        </div>

        <div className={[styles.row, styles.rowPromoCodesTitle].join(" ")}>
          <p className={styles.title}>
            {t("pages.admin.salesShop.promoCodes")}
          </p>
          {refPartner ? null : (
            <button className={styles.uploadButton} onClick={showPromoCodeForm}>
              <Add />
              {t("pages.admin.salesShop.createNewPromoCode")}
            </button>
          )}
        </div>
        <div className={[styles.cardGraph, styles.cardPromos].join(" ")}>
          <div className={[styles.contListCard, styles.listPromos].join(" ")}>
            {renderPromoCodes()}
          </div>
        </div>

        <div className={styles.blankSmall} />
        <div className={styles.row}>
          <p className={styles.title}>{t("pages.admin.salesShop.records")}</p>
        </div>
        <div className={styles.cardGraph}>
          <div className={styles.row}>
            <input
              className={styles.inputsearch}
              placeholder={t("pages.admin.salesShop.searchBy")}
              value={vc.textSearch}
              onChange={(e) => changeState("textSearch", e)}
              onKeyDown={onKeyDown}
            />
            {vc.textSearch || vc.selectedFilter ? (
              <button
                className={styles.filterButton}
                onClick={() =>
                  changeVc({
                    textSearch: "",
                    sells2render: vc.allSells,
                    selectedFilter: "",
                  })
                }
              >
                X
              </button>
            ) : null}
            <button
              className={styles.filterButton}
              onClick={(event) => {
                setAnchorFilter(event.currentTarget);
              }}
            >
              <span>{renderLabelFilter()}</span>
            </button>
            <Menu
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorEl={anchorFilter}
              open={Boolean(anchorFilter)}
              onClose={() => setAnchorFilter(null)}
            >
              <MenuItem onClick={() => filterData("all")}>
                <ListItemText primary={t("pages.admin.salesShop.alla")} />
              </MenuItem>
              <MenuItem onClick={() => filterData("pending")}>
                <ListItemText primary={t("pages.admin.salesShop.pending")} />
              </MenuItem>
              <MenuItem onClick={() => filterData("rejected")}>
                <ListItemText primary={t("pages.admin.salesShop.rejected")} />
              </MenuItem>
              <MenuItem onClick={() => filterData("payed")}>
                <ListItemText primary={t("pages.admin.salesShop.payed")} />
              </MenuItem>
              <MenuItem onClick={() => filterData("approved")}>
                <ListItemText primary={t("pages.admin.salesShop.sent")} />
              </MenuItem>
              <MenuItem onClick={() => filterData("finished")}>
                <ListItemText primary={t("pages.admin.salesShop.delivered")} />
              </MenuItem>
            </Menu>
            <button
              className={styles.searchrButton}
              onClick={() => filterData()}
            >
              {t("pages.admin.salesShop.search")}
            </button>
          </div>
          <div className={styles.contListCard}>
            {renderListSales()}
            {vc.sells2render.length > 0 && vc.sells2render.length > sizeList ? (
              <button
                className={styles.refreshButton}
                onClick={() => setSizeList((ov) => ov + 20)}
              >
                <Refresh /> &nbsp; {t("pages.admin.salesShop.loadMode")}
              </button>
            ) : null}
          </div>
        </div>
        <div className={[styles.row, styles.rowQuickStats].join(" ")}>
          {renderQuickStats("register")}
        </div>
      </div>

      <DialogForm
        show={vc.showPromptPromoCode && !state.loading}
        title={t("pages.admin.salesShop.infoPromoCode")}
        text={t("pages.admin.salesShop.addDataPreferences")}
        subtext={t("pages.admin.salesShop.textMustBeUnique")}
        leftBtText={t("commons.cancel")}
        funcLeft={() =>
          changeVc({
            showPromptPromoCode: false,
            ...initialValuesPromoCode,
          })
        }
        rightBtText={t("commons.accept")}
        funcRight={onSendPromoCode}
        inputs={returnInputsPromoCode()}
        vc={vc}
        changeVc={changePromoCodeValue}
      />
    </>
  );
};

export default SalesShop;
