import React from "react";
import {
  BrowserRouter,
  Route as PublicRoute,
  Switch,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PrivateRoute from "./components/privateRoute";
import { Store } from "./hooks/main_store";
import Model from "./hooks/Model";
import AuthService from "./services/auth";
import EventsService from "./services/events";
import { authService } from "./services/firebase";
import * as Routes from "./utils/routes";

// Import pages
import Main from "./pages/auth/main";
// import Login from "./pages/auth/login";
import Page404 from "./pages/auth/Page404";

import Dashboard from "./pages/dashboard";
// import Bookmarks from "./pages/dashboard/bookmarks";
import Profile from "./pages/profile";

import Events from "./pages/events";
import AllEvents from "./pages/events/allEvents";
import Finishpayment from "./pages/events/finishpayment";
import PaymentGateway from "./pages/events/paymentGateway";

import MyTickets from "./pages/tickets/myTickets";
import DetailTickets from "./pages/tickets/detailTickets";

import Admin from "./pages/admin";
import ContactPublish from "./pages/admin/contactPublish";
import VideoTutorial from "./pages/admin/videoTutorial";
import Team from "./pages/admin/team";
import Sales from "./pages/admin/sales";
import ValidateTicket from "./pages/admin/validateTicket";
import EventForm from "./pages/admin/eventForm";
import ConfirmPartner from "./pages/admin/confirmPartner";
import DetailMember from "./pages/admin/detailMember";

import PartnerProfile from "./pages/partner";
import PluginPartner from "./pages/partner/plugin";
import InfoPlugin from "./pages/partner/infoPlugin";
import Faq from "./pages/dashboard/faq";
import ProductForm from "./pages/admin/productForm";
import DetailProduct from "./pages/shop/detailProduct";
import ShoppingCart from "./pages/shop/shoppingCart";
import SellDetail from "./pages/shop/sellDetail";
import AdminShop from "./pages/admin/shop";
import ShopReports from "./pages/shop/shopReports";
import ProgramInfo from "./pages/partner/programInfo";
import RenderLegalDoc from "./pages/legal/renderLegalDoc";
import CompanySocialMediaLinks from "./pages/legal/companySocialMediaLinks";

const UsePageViews = ({ callback = () => {} }) => {
  const [locationInfo, setLocationInfo] = React.useState();
  let location = useLocation();

  React.useEffect(() => {
    if (callback && locationInfo !== location.pathname) {
      setLocationInfo(location.pathname);
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, locationInfo]);

  return null;
};

const Route = ({ ...props }) => {
  React.useEffect(() => {
    Model.setData("endLoadPrivateRoute", true);
  }, []);

  return <PublicRoute {...props} />;
};

// Create router
const AppRouter = () => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const [cleanTimeout, setCleanTimeout] = React.useState(null);

  const getSettings = async () => {
    try {
      if (!state?.emailVerified && AuthService?.currentUser?.()?.reload) {
        await AuthService.currentUser().reload();
        Model.setData(
          "emailVerified",
          AuthService.currentUser()?.emailVerified
        );
      }

      if (
        state.lastGetSettings &&
        moment().diff(moment(state.lastGetSettings, "x"), "minutes") < 15
      ) {
        return null; // Abort
      }

      Model.setData("lastGetSettings", moment().format("x"));

      const systemSettings = await EventsService.getSettingsEvents();
      const getEventTypes = await EventsService.getEventTypes();

      if (systemSettings) {
        Model.setData("systemSettings", systemSettings);

        if (
          systemSettings?.liveVersion &&
          state.version &&
          systemSettings.liveVersion !== state.version
        ) {
          Model.setData("notification", {
            open: true,
            title: t("system.titleOldVersion"),
            description: t("system.bodyOldVersion", {
              version: state.version,
              liveVersion: systemSettings.liveVersion,
            }),
            image: "coding",
            buttons: [{ label: t("system.reload"), action: "reload" }],
          });
        }
      }

      if (getEventTypes) {
        Model.setData("eventTypes", getEventTypes);
      }
    } catch (e) {
      console.log(t("system.errorGetSettings"), e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  React.useEffect(() => {
    clearTimeout(cleanTimeout);

    if (state.uid) {
      AuthService.getMe(state.uid, false, !state.fistLoad).finally(() =>
        Model.setData("fistLoad", true)
      );
    } else {
      setCleanTimeout(
        setTimeout(() => {
          Model.setData("superLoading", false);
        }, 1000)
      );
    }

    return () => clearTimeout(cleanTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.uid]);

  React.useEffect(() => {
    //Always WWW
    // console.log(window?.location?.host);
    if (
      !window?.location?.host?.includes?.("www.") &&
      !window?.location?.host?.includes?.("test.") &&
      process.env.NODE_ENV === "production"
    ) {
      return window.location.replace(
        `https://www.fravents.com${window?.location?.pathname}`
      );
    }

    const unsubscribe = authService.onAuthStateChanged((user) => {
      if (user) {
        Model.setData("uid", user.uid);
      }
    });

    if (authService.currentUser()) {
      authService
        .currentUser()
        .reload()
        .then(() => {})
        .catch(() => {});
    }

    return () => {
      unsubscribe();
    };
    //eslint-disable-next-line
  }, []);

  if (state.isOnPluginPartner) {
    return (
      <BrowserRouter>
        <UsePageViews callback={getSettings} />
        <Switch>
          <Route path={Routes.HOME} exact component={Main} />
          <Route path={Routes.EVENTS} exact component={Events} />
          <Route path={Routes.FINISH_PAYMENT} exact component={Finishpayment} />
          <Route path={Routes.PLUGIN_PARTNER} exact component={PluginPartner} />
          <Route path={Routes.FAQ} exact component={Faq} />
          <PrivateRoute
            path={Routes.MY_TRANSACTIONS}
            exact
            component={MyTickets}
          />
          <PrivateRoute path={Routes.PROFILE} exact component={Profile} />
          <PrivateRoute
            path={Routes.DETAIL_TICKETS}
            exact
            component={DetailTickets}
          />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <UsePageViews callback={getSettings} />
      <Switch>
        {/* Auth pages */}
        <Route path={Routes.HOME} exact component={Main} />
        {/* <Route path="/login" exact component={Login} /> */}
        {/* Dashboard */}
        <Route path={Routes.DASHBOARD} exact component={Dashboard} />
        {/* <PrivateRoute path="/bookmarks" exact component={Bookmarks} /> */}
        <PrivateRoute path={Routes.PROFILE} exact component={Profile} />
        {/* Events */}
        <Route path={Routes.ALL_EVENTS} exact component={AllEvents} />
        <Route path={Routes.EVENTS} exact component={Events} />
        <Route path={Routes.FINISH_PAYMENT} exact component={Finishpayment} />
        <Route path={Routes.PAYMENT_GATEWAY} exact component={PaymentGateway} />
        <PrivateRoute
          path={Routes.DETAIL_TICKETS}
          exact
          component={DetailTickets}
        />
        <PrivateRoute
          path={Routes.MY_TRANSACTIONS}
          exact
          component={MyTickets}
        />
        {/* Admin */}
        <Route path={Routes.CONTACT_PUBLISH} exact component={ContactPublish} />
        <Route path={Routes.VIDEO_TUTORIAL} exact component={VideoTutorial} />
        <PrivateRoute path={Routes.ADMIN} onlyAdmin exact component={Admin} />
        <PrivateRoute path={Routes.TEAM} onlyAdmin exact component={Team} />
        <PrivateRoute path={Routes.SALES} onlyAdmin exact component={Sales} />
        <PrivateRoute
          path={Routes.VALIDATE_TICKET}
          // onlyAdmin
          exact
          component={ValidateTicket}
        />
        <PrivateRoute
          path={Routes.EVENT_FORM}
          onlyAdmin
          exact
          component={EventForm}
        />
        <Route
          path={Routes.CONFIRM_PARTNER}
          // onlyAdmin
          exact
          component={ConfirmPartner}
        />
        <PrivateRoute
          path={Routes.DETAIL_MEMBER}
          onlyAdmin
          exact
          component={DetailMember}
        />
        <Route path={Routes.DETAIL_PRODUCT} exact component={DetailProduct} />
        <PrivateRoute
          path={Routes.PRODUCT_FORM}
          onlyAdmin
          withShop
          exact
          component={ProductForm}
        />
        <PrivateRoute
          path={Routes.SHOPPING_CART}
          exact
          component={ShoppingCart}
        />
        <PrivateRoute path={Routes.SELL_DETAIL} exact component={SellDetail} />
        <PrivateRoute
          path={Routes.ADMIN_SHOP}
          onlyAdmin
          withShop
          exact
          component={AdminShop}
        />
        <PrivateRoute
          path={Routes.SHOP_REPORTS}
          exact
          component={ShopReports}
        />

        <Route path={Routes.PARTNER_PROFILE} exact component={PartnerProfile} />
        <PrivateRoute
          path={Routes.INFO_PLUGIN}
          exact
          onlyAdmin
          component={InfoPlugin}
        />

        <Route path={Routes.PROGRAM_INFO} exact component={ProgramInfo} />
        <Route
          path={Routes.RENDER_LEGAL_DOC}
          exact
          component={RenderLegalDoc}
        />
        <Route
          path={Routes.COMPANY_SOCIAL_MEDIA_LINKS}
          exact
          component={CompanySocialMediaLinks}
        />

        <Route path={Routes.FAQ} exact component={Faq} />

        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
