import React from "react";
import {
  ConfirmationNumber,
  Bookmarks as Bookmark,
  HelpOutline,
  AttachMoney,
  Cancel,
  Check,
  Block,
  Category,
  PersonAdd,
} from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import moment from "moment";

import LayoutListEvents from "../../components/LayoutListEvents";
import {
  currencyFormat,
  linkMp,
  returnCurrentLink,
} from "../../utils/validations";
import ActionsBanner from "../../components/ActionsBanner";
import SwitchBoth from "../../components/SwitchBoth";
import pageStyles from "./styles/myTicketsStyles";
import PartnerService from "../../services/partner";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import Bookmarks from "../dashboard/bookmarks";
import * as Routes from "../../utils/routes";
import MyProducts from "../shop/myProducts";
import Input from "../../components/input";
import Model from "../../hooks/Model";
import Layout from "../layout";

const MyTickets = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const [show2sell, setShow2sell] = React.useState(false);
  const [showBanners, setShowBanners] = React.useState(true);
  const [ticketsPrices, setTicketsPrices] = React.useState({});
  const [filterEvents, setFilterEvents] = React.useState("soon");
  const [vc, setVc] = React.useState({
    tabSelected: 0,
    currentPage: 1,
    totalPages: 1,
  });

  const onAcceptSellTerms = () => {
    if (state?.userInfo?.secret) {
      setShow2sell(true);
      setShowBanners(false);
      setTimeout(() => {
        setShowBanners(true);
      }, 100);
      Model.updateAlerts({
        message: t("pages.tickets.myTickets.selectPriceUnit"),
        variant: "warning",
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: t("pages.tickets.myTickets.mustLinkAccount"),
        text: t("pages.tickets.myTickets.toSellMustLinkAccount"),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("pages.tickets.myTickets.link"),
        fnRight: linkMp,
      });
    }
  };

  const onSaleTickets = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.myTickets.beforeContinue"),
      text: state?.emailVerified
        ? t("pages.tickets.myTickets.everySellAccept")
        : t("pages.tickets.myTickets.mustVerifyEmail"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: state?.emailVerified
        ? t("commons.accept")
        : t("pages.tickets.myTickets.goToProfile"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        if (state?.emailVerified) {
          onAcceptSellTerms();
        } else {
          history.push("/profile");
        }
      },
      link: returnCurrentLink(Routes.RENDER_LEGAL_DOC + "?document=resale"),
    });
  };

  const confirmSellTickets = async () => {
    let finishWithoutError = true;
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      const tickets2sell = { ...ticketsPrices };
      Object.keys(ticketsPrices).forEach((keyTicket) => {
        if (
          !(
            ticketsPrices[keyTicket]?.price >= 1000 &&
            ticketsPrices[keyTicket]?.quantity > 0
          )
        ) {
          delete tickets2sell[keyTicket];
        }
      });
      await EventsService.sellMyTickets({
        tickets: tickets2sell,
        test: window?.location?.host?.includes?.("test."),
      });
      setShow2sell(false);
      setTicketsPrices({});
      Model.updateAlerts({
        message: t("pages.tickets.myTickets.ticketsOnSell"),
        variant: "success",
      });
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.tickets.myTickets.errorToSell")}: ` + String(e),
        variant: "error",
      });
      finishWithoutError = false;
    } finally {
      Model.setData("loading", false);
      if (finishWithoutError) {
        getMyTickets(true);
      }
    }
  };

  const onSellTickets = () => {
    const currentSells = Object.keys(ticketsPrices)
      .map((keyTicket) => ticketsPrices[keyTicket])
      .filter((ticket) => ticket?.price >= 1000 && ticket?.quantity > 0);

    if (currentSells.length < 1) {
      return Model.updateAlerts({
        message: t("pages.tickets.myTickets.mustAddPrice"),
        variant: "error",
      });
    }

    if (currentSells.length !== Object.keys(ticketsPrices).length) {
      Model.updateAlerts({
        message: t("pages.tickets.myTickets.someSellsNoShow"),
        variant: "warning",
      });
    }

    Model.setData("dialog", {
      open: true,
      title: t("pages.tickets.myTickets.confirmSelect"),
      text: `${t(
        "pages.tickets.myTickets.areSureSellTicket"
      )}\n${currentSells.map(
        (sell) =>
          `\n${sell?.name || "No name"} - x${sell?.quantity || 0} ticket${
            sell?.quantity === 1 ? "" : "s"
          } - ${currencyFormat(sell?.price, "COP", 0)} c/u`
      )}`,
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.confirm"),
      fnRight: confirmSellTickets,
    });
  };

  const onNoMobiles = () => {
    Model.updateAlerts({
      message: t("pages.tickets.myTickets.mustLoginPc"),
      variant: "warning",
    });
  };

  const banners = show2sell
    ? [
        {
          title: t("pages.tickets.myTickets.cancelSell"),
          color: "red",
          onPress: () => {
            Model.setData("dialog", {
              open: true,
              title: t("pages.tickets.myTickets.cancelSell"),
              text: t("pages.tickets.myTickets.areSureCancelSell"),
              txtLeft: t("commons.cancel"),
              fnLeft: () => Model.setData("dialog", { open: false }),
              txtRight: t("commons.accept"),
              fnRight: () => {
                Model.setData("dialog", { open: false });
                setShow2sell(false);
                setTicketsPrices({});
                setShowBanners(false);
                setTimeout(() => {
                  setShowBanners(true);
                }, 100);
              },
            });
          },
          Icon: (props = {}) => <Cancel {...props} />,
        },
        {
          title: t("pages.tickets.myTickets.confirmSell"),
          color: "green",
          onPress: onSellTickets,
          Icon: (props = {}) => <Check {...props} />,
        },
      ]
    : [
        {
          title: t("pages.tickets.myTickets.sellYourTickets"),
          description: t("pages.tickets.myTickets.setPrice"),
          color: "green",
          onPress: onSaleTickets,
          Icon: (props = {}) => <AttachMoney {...props} />,
          onlyDesktop: true,
        },
        {
          title: t("pages.tickets.myTickets.sellYourTickets"),
          description: t("pages.tickets.myTickets.noForMobile"),
          color: "",
          onPress: onNoMobiles,
          Icon: (props = {}) => <Block {...props} />,
          onlyMobile: true,
        },
        {
          title: t("pages.tickets.myTickets.refAndEarn"),
          description: t("pages.tickets.myTickets.shareLink"),
          color: "orange",
          onPress: () => history.push("/profile?tab=1"),
          Icon: (props = {}) => <PersonAdd {...props} />,
        },
      ];

  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const onSelectEvent = (item) => {
    Model.setData("ticketSelected", item);
    history.push("/detailTickets");
  };

  const handlePageChange = (e, value) => {
    changeVc({ currentPage: value });
  };

  const renderCurrentView = () => {
    if (vc.tabSelected === 0) {
      return renderTickets();
    } else if (vc.tabSelected === 1) {
      return <MyProducts history={history} getEvents={getEvents} />;
    } else if (vc.tabSelected === 2) {
      return <Bookmarks history={history} getEvents={getEvents} />;
    }
  };

  const changeNumberSellInput = (ref, item) => (event) => {
    event.persist();

    const maxData =
      item?.data?.tickets?.filter?.(
        (ticket) =>
          !ticket?.transferedTo &&
          ticket.status === "pending" &&
          !ticket.isSelling &&
          ticket.price > 0
      )?.length || 0;
    const intValue = parseInt(event.target.value);
    const newDataTicketsPrices = { ...ticketsPrices };
    newDataTicketsPrices[item.uid] = newDataTicketsPrices[item.uid] || {
      price: 0,
      quantity: 0,
      name: item?.data?.event?.name,
    };
    newDataTicketsPrices[item.uid][ref] =
      ref === "price"
        ? intValue < 0
          ? 0
          : intValue
        : intValue > maxData
        ? maxData
        : intValue > 0
        ? intValue
        : 0;

    setTicketsPrices(newDataTicketsPrices);
  };

  const returnEventsData = React.useCallback(() => {
    const filteredTickets = [...(state?.myTickets || [])]
      .filter((item) => {
        const hasStatus =
          item?.data?.status === "payed" ||
          item?.data?.status === "refunded" ||
          (show2sell ? false : item?.data?.status === "pending");
        const eventInfo =
          state.events.filter(
            (event) => event.uid === item?.data?.event?.uid
          )?.[0] ||
          state.draftEvents.filter(
            (event) => event.uid === item?.data?.event?.uid
          )?.[0];
        const isFinished = !!eventInfo
          ? moment().add(-1, "day").format("x") > eventInfo?.date
          : !show2sell;
        let countSelling = 0;
        let isSellingAll = false;

        if (item?.data?.tickets?.length > 0) {
          item.data.tickets.forEach((ticket) => {
            if (
              ticket?.isSelling ||
              ticket?.transferedTo ||
              ticket?.status === "validated" ||
              ticket?.price === 0
            ) {
              countSelling++;
            }
          });
        }

        if (countSelling === item?.data?.tickets?.length) {
          isSellingAll = true;
        }

        return show2sell
          ? hasStatus && !isSellingAll && !isFinished
          : hasStatus && (filterEvents === "soon" ? !isFinished : true);
      })
      .filter((item) =>
        state?.isOnPluginPartner || state?.isWhiteLabel
          ? state?.eventsPartnerPlugin?.indexOf?.(item?.data?.event?.uid) >
              -1 || false
          : true
      )
      .sort((a, b) => b?.data?.created - a?.data?.created);

    return filteredTickets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show2sell, state, filterEvents]);

  const renderEventsTable = () => {
    const filteredTickets = returnEventsData();
    if (filteredTickets.length < 1) {
      return (
        <tr>
          <td colSpan="5">{t("pages.tickets.myTickets.noHaveAnyEvent")}</td>
        </tr>
      );
    }
    return filteredTickets
      .slice((vc.currentPage - 1) * 10, vc.currentPage * 10 - 1)
      .map((item, index) => (
        <tr key={"item-list-event-" + index}>
          <td>
            {item?.data?.tickets?.filter?.(
              (ticket) =>
                !ticket?.transferedTo &&
                ticket.status === "pending" &&
                !ticket.isSelling 
                // && ticket.price > 0
            )?.length || 0}
          </td>
          <td>{item?.data?.event?.name?.slice(0, 36)}</td>
          <td>
            {show2sell
              ? moment(item?.data?.created, "x").format("DD MMM YYYY, H:mm")
              : item?.data?.status === "pending"
              ? t("pages.tickets.myTickets.pending")
              : item?.data?.status === "refunded"
              ? t("pages.tickets.myTickets.refunded")
              : t("pages.tickets.myTickets.confirm")}
          </td>
          <td>
            {show2sell ? (
              <Input
                customStyles={styles.inputNumber}
                type="number"
                placeholder="$COP"
                onChange={changeNumberSellInput("price", item)}
                value={
                  isNaN(parseInt(ticketsPrices?.[item.uid]?.price))
                    ? ""
                    : ticketsPrices?.[item.uid]?.price
                }
              />
            ) : (
              moment(item?.data?.created, "x").format("DD MMM YYYY, H:mm")
            )}
          </td>
          <td>
            {show2sell ? (
              <Input
                customStyles={styles.inputNumber}
                type="number"
                placeholder={`${
                  item?.data?.tickets?.filter?.(
                    (ticket) =>
                      !ticket?.transferedTo &&
                      ticket.status === "pending" &&
                      !ticket.isSelling &&
                      ticket.price > 0
                  )?.length || 0
                } max.`}
                onChange={changeNumberSellInput("quantity", item)}
                value={
                  isNaN(parseInt(ticketsPrices?.[item.uid]?.quantity))
                    ? ""
                    : ticketsPrices?.[item.uid]?.quantity
                }
              />
            ) : (
              <button
                className={styles.viewMoreButton}
                onClick={() => onSelectEvent(item)}
              >
                {t("pages.tickets.myTickets.viewDetails")}
              </button>
            )}
          </td>
        </tr>
      ));
  };

  const renderEventsList = () => {
    const filteredTickets = returnEventsData();
    if (filteredTickets.length < 1) {
      return (
        <div className={styles.emptyList}>
          {t("pages.tickets.myTickets.weNoHaveEvent")}
        </div>
      );
    }
    return filteredTickets
      .slice((vc.currentPage - 1) * 10, vc.currentPage * 10 - 1)
      .map((item, index) => (
        <div
          key={"item-list-event-" + index}
          onClick={() => onSelectEvent(item)}
          className={styles.containerEventList}
        >
          <div className={styles.topInfoEventList}>
            <span className={styles.textTopEventList}>
              {t("pages.tickets.myTickets.quantity")}:{" "}
              {item?.data?.tickets?.length}
            </span>
            <span className={styles.textTopEventList}>
              {item?.data?.status === "pending"
                ? t("pages.tickets.myTickets.pending")
                : item?.data?.status === "refunded"
                ? t("pages.tickets.myTickets.refunded")
                : t("pages.tickets.myTickets.confirm")}
            </span>
          </div>
          <div className={styles.bodyInfoEventList}>
            <div className={styles.contImgEventList}>
              <img
                alt="event"
                src={item?.data?.event?.imageBanner}
                className={styles.imgEventList}
              />
            </div>
            <p className={styles.nameEventList}>{item?.data?.event?.name}</p>
          </div>
        </div>
      ));
  };

  const renderTickets = () => (
    <>
      {vc.tabSelected === 0 && !show2sell ? (
        <div className={styles.switchContainer}>
          <SwitchBoth
            onChange={(value) => setFilterEvents(value)}
            value={filterEvents}
            values={[
              { label: t("pages.tickets.myTickets.all"), value: "all" },
              { label: t("pages.tickets.myTickets.next"), value: "soon" },
            ]}
          />
        </div>
      ) : null}
      <table cellPadding={0} cellSpacing={0} className={styles.tableContainer}>
        <thead className={styles.rowHeadTable}>
          <tr>
            <th style={{ width: "10%" }}>
              {t("pages.tickets.myTickets.quantity")}
            </th>
            <th style={{ width: "40%" }}>
              {t("pages.tickets.myTickets.event")}
            </th>
            <th style={{ width: "15%" }}>
              {show2sell
                ? t("pages.tickets.myTickets.buyDate")
                : t("pages.tickets.myTickets.state")}
            </th>
            <th style={{ width: "20%" }}>
              {show2sell
                ? t("pages.tickets.myTickets.unitPrice")
                : t("pages.tickets.myTickets.buyDate")}
            </th>
            <th style={{ width: "15%" }}>
              {show2sell
                ? t("pages.tickets.myTickets.quantitySell")
                : t("pages.tickets.myTickets.qrCode")}
            </th>
          </tr>
        </thead>
        <tbody className={styles.rowBodyTable}>{renderEventsTable()}</tbody>
      </table>
      <div className={styles.listTickets}>
        <h2 className={styles.titleList}>
          {t("pages.tickets.myTickets.myTickets")}
        </h2>
        {renderEventsList()}
      </div>
      <div className={styles.containerPagination}>
        <Pagination
          count={vc.totalPages}
          page={vc.currentPage}
          onChange={handlePageChange}
        />
      </div>
    </>
  );

  const getEvents = async () => {
    try {
      Model.setData("loading", !(state?.events?.length > 0));
      await EventsService.getEvents();
    } catch (e) {
      console.log(t("pages.tickets.myTickets.errorToGetEvents"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  const getMyTickets = async (forceLoading = false) => {
    try {
      Model.setData("loading", forceLoading || !(state?.myTickets?.length > 0));
      await EventsService.getMyTickets();
    } catch (e) {
      console.log(t("pages.tickets.myTickets.errorGetTickets"), e);
    } finally {
      Model.setData("loading", false);
      getEvents();
    }
  };

  const getPartner = async (tokenPartner) => {
    try {
      const uidPartner = await PartnerService.getUidPartner(tokenPartner);

      const infoPartner = await PartnerService.getPartner(uidPartner);

      if (!state.fistLoad) {
        Model.setData("isWhiteLabel", !!infoPartner?.basicInfo?.isWhiteLabel);
      }
      
      Model.setData(
        "eventsPartnerPlugin",
        (infoPartner?.events || [])?.map?.((event) => event?.uid) || []
      );
    } catch (e) {
      Model.setData(
        "eventsPartnerPlugin",
        []
      );
    }
  };

  React.useEffect(() => {
    changeVc({
      totalPages: Math.ceil((returnEventsData()?.length || 0) / 10) || 1,
      currentPage: 1,
    });
    //eslint-disable-next-line
  }, [state, vc.tabSelected, show2sell, filterEvents]);

  React.useEffect(() => {
    Model.setData("opacity", 0.7);
    getMyTickets();
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uidPartner = urlParams.get("ref");

    if (uidPartner) {
      getPartner(uidPartner)
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Layout hideFooterLinks={!!state?.isOnPluginPartner} blackHeader>
      <LayoutListEvents
        tabSelected={vc.tabSelected}
        topBtns={[
          {
            label: t("pages.tickets.myTickets.tickets"),
            callBack: () => changeVc({ tabSelected: 0 }),
            icon: <ConfirmationNumber />,
          },
          {
            label: t("pages.tickets.myTickets.products"),
            icon: <Category />,
            callBack: () => changeVc({ tabSelected: 1 }),
          },
          {
            label: t("pages.tickets.myTickets.favorites"),
            icon: <Bookmark />,
            callBack: () => changeVc({ tabSelected: 2 }),
          },
        ]}
        bottomBtns={
          !state?.isOnPluginPartner
            ? [
                {
                  label: t("pages.tickets.myTickets.support"),
                  icon: <HelpOutline />,
                  callBack: () => window.open("mailto:help@fravents.com"),
                },
              ]
            : []
        }
      >
        {showBanners && vc.tabSelected === 0 && !state?.isOnPluginPartner ? (
          <ActionsBanner banners={banners} className={styles.banners} />
        ) : null}
        {renderCurrentView()}
      </LayoutListEvents>
    </Layout>
  );
};

export default MyTickets;
