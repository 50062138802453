import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  returnButton: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    backgroundColor: colors.themeColor,
    color: colors.white,
    padding: "12px 0 18px 0",
    width: "100%",
    display: "none",
    top: 77,
    left: 0,
    fontSize: 16,
    position: "fixed",
    zIndex: 1,
    fontWeight: "600",
    boxShadow: colors.shadow,
    margin: 0,
    [Commons.smallQuery]: {
      display: "block",
    },
    "& svg": {
      fontSize: 24,
      marginRight: 6,
      position: "relative",
      top: 6,
    },
  },
  rowContent: {
    ...Commons.flexRow,
    width: "100%",
    backgroundColor: colors.white,
    alignItems: "flex-start",
  },
  menuNavigation: {
    flex: 1,
    borderRight: `1px solid ${colors.themeColor}`,
    minWidth: 235,
    [Commons.smallQuery]: {
      minWidth: "none",
    },
  },
  scrollCont: {
    flex: 3,
    borderLeft: `1px solid ${colors.themeColor}`,
    maxHeight: 720,
    overflow: "auto",
    [Commons.smallQuery]: {
      maxHeight: "none",
      marginTop: 55,
    },
  },
  contBanner: {
    ...Commons.flexColumn,
    width: "100%",
    backgroundColor: colors.themeColor,
  },
  changeImgBtn: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    borderRadius: "50%",
    width: 120,
    height: 120,
    backgroundColor: colors.white,
    marginTop: 42,
    boxShadow: colors.shadow,
  },
  inputImage: {
    position: "absolute",
    zIndex: -1,
    opacity: 0,
  },
  imgProfile: {
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    minWidth: 116,
    minHeight: 116,
    objectFit: "cover",
    overflow: "hidden",
    border: "2px solid white",
  },
  iconChangePhoto: {
    ...Commons.flexCenter,
    border: "2px solid white",
    backgroundColor: colors.themeColor,
    borderRadius: "50%",
    width: 30,
    height: 30,
    position: "absolute",
    bottom: 0,
    right: 0,
    boxShadow: colors.shadow,
    "& svg": {
      fontSize: 18,
      color: colors.white,
    },
  },
  nameUser: {
    color: colors.white,
    fontSize: 30,
    margin: "18px 0px 6px 0px",
    fontWeight: "700",
  },
  emailUser: {
    color: colors.white,
    fontSize: 18,
    margin: "0px 0px 60px 0px",
  },
  listItemsNavigation: {
    width: "100%",
    paddingBottom: 90,
    paddingTop: 12,
  },
  itemListNavigation: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    color: colors.black,
    padding: "18px 6px",
    width: "100%",
    borderBottom: `2px solid ${colors.grayLight}`,
    fontSize: 15,
    fontWeight: "600",
    "& svg": {
      fontSize: 24,
      margin: "0 12px",
    },
    "&.selected": {
      color: colors.themeColor,
      borderColor: colors.themeColor,
    },
    "&:hover": {
      color: colors.themeColor,
      borderColor: colors.themeColor,
    },
  },
  contForm: {
    maxWidth: 700,
    margin: "0 auto",
    padding: "0px 24px",
    marginBottom: 30,
  },
  labelSection: {
    fontSize: 24,
    fontWeight: 700,
    margin: "18px 0px 24px 0px",
    borderTop: `1px solid ${colors.grayBlack}`,
    paddingTop: 18,
  },
  descriptionSection: {
    textAlign: "center",
    color: colors.gray50,
    margin: "0px 0px 24px 0px",
  },
  rowBenefit: {
    ...Commons.flexRow,
    backgroundColor: colors.grayLight,
    padding: "18px 6px",
    gap: 30,
    marginBottom: 12,
    [Commons.smallQuery]: {
      flexDirection: "column",
      gap: 12,
    },
  },
  nameBenefit: {
    textAlign: "left",
    margin: 0,
    fontSize: 14,
    fontWeight: "600",
    color: colors.black,
    [Commons.smallQuery]: {
      textAlign: "center",
    },
  },
  valueBenefit: {
    margin: 0,
    fontSize: 42,
    color: colors.themeColor,
    fontWeight: "700",
  },
  badgeBenefit: {
    fontSize: 18,
    color: colors.black,
  },
  descriptionBenefit: {
    textAlign: "right",
    margin: 0,
    flex: 1,
    fontSize: 14,
    color: colors.black,
    [Commons.smallQuery]: {
      textAlign: "center",
    },
  },
  rowInputsWrap: {
    ...Commons.flexRow,
    flexWrap: "wrap",
  },
  rowInput: {
    ...Commons.flexRow,
    margin: "6px 0px 18px 0px",
    width: "100%",
    "& svg": {
      fontSize: 36,
      color: colors.gray50,
      marginRight: 12,
    },
  },
  rowInput50: {
    width: "calc(50% - 24px)",
    margin: "6px 24px 18px 0",
    overflow: 'hidden',
    [Commons.smallQuery]: {
      width: "100%",
      margin: "6px 0px 18px 0px",
    },
  },
  rowInputPass: {
    ...Commons.flexRow,
    flex: 1,
  },
  eyeBtn: {
    ...Commons.defaultButton,
  },
  imagePrevInput: {
    width: 50,
    height: 50,
    objectFit: "cover",
    backgroundColor: colors.white,
    marginRight: 12,
    borderRadius: 6,
    boxShadow: colors.shadow,
  },
  deleteImageBtn: {
    ...Commons.defaultButton,
    "& svg": {
      fontSize: 24,
      color: colors.red,
    },
  },
  contInput: {
    flex: 1,
    maxWidth: "calc(100% - 90px)",
  },
  labelInput: {
    margin: 0,
    fontWeight: 700,
    fontSize: 16,
  },
  input: {
    ...Commons.defaultInput,
    width: "100%",
    padding: "6px 0px",
    fontSize: 18,
  },
  verifyBtn: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    height: 48,
    backgroundColor: colors.red,
    borderRadius: 12,
    color: colors.white,
    fontWeight: 600,
    fontSize: 14,
    margin: "12px auto",
    boxShadow: colors.shadow,
    width: "100%",
  },
  rowOptions: {
    ...Commons.flexRow,
    overflow: "auto",
    flexWrap: "wrap",
    margin: "6px 0",
  },
  colorBtn: {
    ...Commons.defaultButton,
    borderRadius: "50%",
    width: 42,
    height: 42,
    margin: 6,
  },
  bordered: {
    border: "3px solid black",
    boxShadow: colors.shadow,
  },
  green: {
    color: colors.primary,
  },
  red: {
    color: colors.red,
  },
  rowBtns: {
    ...Commons.flexRow,
    gap: 12,
    [Commons.smallQuery]: {
      flexWrap: "wrap",
      gap: 6,
    },
  },
  saveBtn: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    height: 48,
    backgroundColor: colors.grayLight,
    borderRadius: 12,
    color: colors.black,
    fontWeight: 600,
    fontSize: 14,
    margin: "12px auto",
    boxShadow: colors.shadow,
    width: "100%",
    opacity: 0.5,
  },
  themeColor: {
    backgroundColor: colors.themeColor,
    color: colors.white,
    opacity: 1,
  },
  referCard: {
    ...Commons.flexRow,
    backgroundColor: colors.black,
    borderRadius: 12,
    boxShadow: colors.shadow,
    padding: 24,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  infoContainerRefer: {
    flex: 1,
    color: colors.white,
    [Commons.smallQuery]: {
      width: "100%",
    },
  },
  titleRefer: {
    fontWeight: "700",
    fontSize: 30,
    margin: "0 0 12px 0",
    [Commons.smallQuery]: {
      textAlign: "center",
    },
  },
  descriptionRefer: {
    fontSize: 16,
    margin: 0,
    "& a": {
      color: colors.themeColor,
    },
    [Commons.smallQuery]: {
      textAlign: "center",
    },
  },
  buttonRefer: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    color: colors.white,
    backgroundColor: colors.themeColor,
    marginTop: 18,
    borderRadius: 12,
    fontSize: 15,
    textAlign: "center",
    padding: "12px 0",
    width: "100%",
    fontWeight: "700",
  },
  imageRefer: {
    width: 180,
    marginLeft: 18,
    [Commons.smallQuery]: {
      marginLeft: 0,
      marginTop: 18,
    },
  },
  containerStatsRefer: {
    backgroundColor: colors.white,
    borderRadius: 12,
    boxShadow: colors.shadow,
    padding: 24,
    border: `6px solid ${colors.themeColor}`,
    textAlign: "center",
  },
  titleStatsRefer: {
    textAlign: "center",
    color: colors.black,
    fontSize: 24,
    margin: "12px 0 0 0",
    fontWeight: "700",
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  rowStats: {
    ...Commons.flexRow,
    width: "100%",
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  itemRowStats: {
    flex: 1,
  },
  quantityStatsRefer: {
    textAlign: "center",
    fontSize: 36,
    fontWeight: "700",
    margin: "24px 0",
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  descriptionStatsRefer: {
    textAlign: "center",
    color: colors.gray50,
    fontSize: 16,
    margin: "0 0 12px 0",
  },
  imageDummyStatsRefer: {
    width: "100%",
    margin: "18px 0",
    maxWidth: 360,
    borderRadius: 12,
    overflow: "hidden",
    boxShadow: colors.shadow,
  },
  separatorStatsRefer: {
    width: "100%",
    margin: "24px 0",
    borderTop: `3px dashed ${colors.themeColor}`,
  },
  optionButton: {
    ...Commons.defaultButton,
    textAlign: "center",
    padding: "6px 12px",
    borderRadius: 6,
    border: `2px solid ${colors.grayBlack}`,
    color: colors.black,
    fontSize: 14,
    transition: "all 0.2s",
    margin: "6px 12px 6px 0",
    "&:hover": {
      backgroundColor: colors.themeColor,
      color: colors.white,
      borderColor: colors.themeColor,
    },
  },
  selectedOption: {
    backgroundColor: colors.themeColor,
    color: colors.white,
    borderColor: colors.themeColor,
  },
  switchContainer: {
    margin: "0px auto 12px auto",
    [Commons.smallQuery]: {
      margin: "-30px auto 12px auto",
    },
  },
  code2ref: {
    fontWeight: "bold",
    fontSize: 48,
    textAlign: "center",
    margin: "18px auto 6px auto",
    boxShadow: colors.shadow,
    backgroundColor: colors.white,
    borderRadius: 12,
    width: "fit-content",
    padding: "6px 18px",
  },
});

export default styles;
