import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
import { CheckCircle, ArrowDownward } from "@material-ui/icons";

import PartnerProposalFile from "../../assets/documents/partnerProposal.pdf";
import { returnCurrentLink, verifyEmail } from "../../utils/validations";
import * as AvatarImages from "../../assets/images/tutorial";
import VideoBanner from "../../components/VideoBanner";
import DialogForm from "../../components/dialogForm";
import pageStyles from "./styles/programInfoStyles";
import PartnerService from "../../services/partner";
import LocalStorage from "../../utils/localStorage";
import { Store } from "../../hooks/main_store";
import AuthService from "../../services/auth";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const ProgramInfo = ({ history }) => {
  const { state } = React.useContext(Store);
  const { t } = useTranslation();
  const styles = pageStyles();
  const localWatched = LocalStorage(state.isIncognito).getItem(
    "watched_program_video"
  );
  const [numPages, setNumPages] = React.useState(0);
  const [showDocument, setshowDocument] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [showProgram, setShowProgram] = React.useState(false);
  const [waitForForm, setWaitForForm] = React.useState(false);
  const [showFeatures, setShowFeatures] = React.useState([false, false]);
  const initialValues = {
    name: "",
    lastName: "",
    email: state?.userInfo?.email,
    phone: "",
    openConfirmCode: false,
  };
  const [vc, setVc] = React.useState(initialValues);
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    Model.setData("loading", false);
  };

  const onCompleteVideo = (secondsPlayed) => {
    if (
      secondsPlayed >
      (process.env.NODE_ENV === "development" ||
      window?.location?.host?.includes?.("test.")
        ? 1
        : 30)
    ) {
      LocalStorage(state.isIncognito).setItem("watched_program_video", "done");
      Model.setData("dialog", {
        open: true,
        title: t("pages.partner.programInfo.continue"),
        text: t("pages.partner.programInfo.messageContinue"),
        txtRight: t("commons.ok"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          setshowDocument(true);
          Model.setData("moveLayoutScroll", "programInfo");
        },
      });
    } else {
      Model.setData("dialog", {
        open: true,
        title: t("pages.partner.programInfo.noContinue"),
        text: t("pages.partner.programInfo.messageNoContinue"),
        txtRight: t("commons.ok"),
        fnRight: () => Model.setData("dialog", { open: false }),
      });
    }
  };

  const onShowForm = () => {
    setWaitForForm(false);
    if (localWatched === "done" && !showDocument) {
      Model.setData("loading", true);
      setshowDocument(true);
      Model.setData("moveLayoutScroll", "programInfo");
    } else if (showDocument) {
      if (!state.uid) {
        Model.setData("openAuth", true);
        setWaitForForm(true);
      }
      // else if (!state?.emailVerified) {
      //   Model.setData("dialog", {
      //     open: true,
      //     title: t("pages.events.indexEvent.beforeContinue"),
      //     text: t("pages.partner.programInfo.mustVerifyEmail"),
      //     txtLeft: t("commons.cancel"),
      //     fnLeft: () => {
      //       Model.setData("dialog", { open: false });
      //     },
      //     txtRight: t("pages.events.indexEvent.goToProfile"),
      //     fnRight: () => {
      //       Model.setData("dialog", { open: false });
      //       history.push("/profile");
      //     },
      //   });
      // }
      else {
        // setShowForm(true);
        window.open(
          "https://calendly.com/fravents/entrevista-inicial-socios-promotores",
          "blank"
        );
      }
    } else {
      Model.setData("dialog", {
        open: true,
        title: t("pages.partner.programInfo.noContinue"),
        text: t("pages.partner.programInfo.messageNoContinue"),
        txtRight: t("commons.ok"),
        fnRight: () => Model.setData("dialog", { open: false }),
      });
    }
  };

  const onConfirmSend = async () => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });
      setShowForm(false);
      changeVc(initialValues);

      const emailLower = vc.email.toLowerCase().replace(/ /g, "");
      const data = {
        name: vc.name,
        lastName: vc.lastName,
        email: emailLower,
        phone: vc.phone,
      };

      await PartnerService.sendFormPartnerProgram(data);

      Model.updateAlerts({
        message: t("pages.partner.programInfo.formSent"),
        variant: "success",
      });

      Model.setData("dialog", {
        open: true,
        title: t("pages.partner.programInfo.thanksForSendTitle"),
        text: t("pages.partner.programInfo.thanksForSendText"),
        txtRight: t("commons.ok"),
        fnRight: () => Model.setData("dialog", { open: false }),
      });

      history.push("/");
    } catch (e) {
      Model.updateAlerts({
        message: e?.message || String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSendForm = () => {
    if (!vc.name || !vc.lastName || !vc.phone || !vc.email) {
      Model.updateAlerts({
        message: t("components.contentEvent.completeDataTry"),
        variant: "error",
      });
    } else if (!verifyEmail(vc.email)) {
      Model.updateAlerts({
        message: t("components.contentEvent.enteredEmailBadFormat"),
        variant: "error",
      });
    } else {
      onConfirmSend();
    }
  };

  const renderFuncionalities = (isFree = true, children, showAll) => {
    const data = isFree
      ? [
          t("pages.partner.programInfo.commissionPercentageCalculated"),
          t("pages.partner.programInfo.startReferencingInstantly"),
          t("pages.partner.programInfo.eachCustomerRefer10"),
          t("pages.partner.programInfo.eachReferralReceives"),
        ]
      : [
          t("pages.partner.programInfo.commissionPercentageCalculated"),
          t("pages.partner.programInfo.simpleWQuickSelection"),
          t("pages.partner.programInfo.eachCustomerRefer50"),
          t("pages.partner.programInfo.accessPrivateCommunity"),
          t("pages.partner.programInfo.receiveBenefits"),
          t("pages.partner.programInfo.youhavePossibility"),
        ];
    return (
      <div className={styles.contBenefits}>
        {(showAll ? data : data.slice(0, 2)).map((item, index) => (
          <div className={styles.rowFunction} key={"item-index-" + index}>
            <CheckCircle className={styles.iconFunction} />
            <p className={styles.textFunction}>{item}</p>
          </div>
        ))}
        {showAll && children}
      </div>
    );
  };

  const onShowProgram = () => {
    setShowProgram(true);
    Model.setData("moveLayoutScroll", "programInfo");
  };

  const onActivateCode = () => {
    if (state.uid) {
      if (state?.userInfo?.acceptPromoCode) {
        history.push("/profile?tab=1&view=clients");
      } else {
        changeVc({ openConfirmCode: true });
      }
    } else {
      Model.setData("openAuth", true);
    }
  };

  const onAcceptConfirmCode = async () => {
    try {
      changeVc({ openConfirmCode: false });

      Model.setData("loading", true);
      const dataCode2Ref = await PartnerService.acceptPromoterCode();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await AuthService.updateMe(state.uid, { acceptPromoCode: true });
      Model.updateUserInfo({
        referStats: {
          ...(state?.userInfo?.referStats || {}),
          earnedClientsQuantity: 0,
          referedDate: dataCode2Ref?.created,
          code2ref: dataCode2Ref?.code,
          percentCode2ref: dataCode2Ref?.percent,
        },
      });

      history.push("/profile?tab=1&view=clients");
    } catch (e) {
      Model.updateAlerts({
        message: `Error: ${e || t("commons.unknowError")}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    Model.setData("moveLayoutScroll", "");
    Model.setData("loading", false);
    Model.setData("opacity", 0.7);

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  React.useEffect(() => {
    if (state?.userInfo?.email) {
      changeVc({ email: state?.userInfo?.email });
    }
    if (state.uid && waitForForm && state?.userInfo?.email) {
      // setShowForm(true);
      window.open(
        "https://calendly.com/fravents/entrevista-inicial-socios-promotores",
        "blank"
      );
    }
    // eslint-disable-next-line
  }, [state.userInfo, state.uid]);

  return (
    <Layout blackHeader>
      {!showProgram ? (
        <>
          <h2 className={styles.title}>
            {t("pages.partner.programInfo.selectOneProgram")}
          </h2>

          <p className={styles.description}>
            {t("pages.partner.programInfo.descriptionIntro")}
          </p>

          <div className={styles.row}>
            <div className={styles.cardPrice}>
              <img
                alt="organizer"
                src={AvatarImages.yei}
                className={styles.imageRole}
              />
              <div className={styles.titleCardPrice}>
                {t("pages.partner.programInfo.referenceEarn")}
              </div>
              <p className={styles.textCardPrice}>
                {t("pages.partner.programInfo.referenceEarnDesription")}
              </p>
              <div className={styles.amountCardPrice}>
                {t("pages.partner.programInfo.referenceEarnRate")}
              </div>
              {renderFuncionalities(true, null, showFeatures[0])}
              {showFeatures[0] ? null : (
                <div
                  className={styles.viewMoreFeat}
                  onClick={() => setShowFeatures((ov) => [!ov[0], ov[1]])}
                >
                  <ArrowDownward
                    style={{ fontSize: 16, top: 3, position: "relative" }}
                  />{" "}
                  {t("pages.partner.programInfo.viewMoreBene")}
                  <ArrowDownward
                    style={{ fontSize: 16, top: 3, position: "relative" }}
                  />
                </div>
              )}
              <div className={styles.ButtonCardPrice} onClick={onActivateCode}>
                {t(
                  `pages.partner.programInfo.${
                    state?.userInfo?.acceptPromoCode
                      ? "viewMyCode"
                      : "activateCode"
                  }`
                )}
              </div>
            </div>
            <div className={styles.cardPrice}>
              <img
                alt="organizer"
                src={AvatarImages.starEyes}
                className={styles.imageRole}
              />
              <div className={styles.titleCardPrice}>
                {t("pages.partner.programInfo.salesAgent")}
              </div>
              <p className={styles.textCardPrice}>
                {t("pages.partner.programInfo.salesAgentDescription")}
              </p>
              <div className={styles.amountCardPrice}>
                {t("pages.partner.programInfo.salesAgentRate")}
              </div>
              {renderFuncionalities(
                false,
                <div className={styles.textNote}>
                  {t("pages.partner.programInfo.extendCondition")}
                </div>,
                showFeatures[1]
              )}
              {showFeatures[1] ? null : (
                <div
                  className={styles.viewMoreFeat}
                  onClick={() => setShowFeatures((ov) => [ov[0], !ov[1]])}
                >
                  <ArrowDownward
                    style={{ fontSize: 16, top: 3, position: "relative" }}
                  />{" "}
                  {t("pages.partner.programInfo.viewMoreBene")}
                  <ArrowDownward
                    style={{ fontSize: 16, top: 3, position: "relative" }}
                  />
                </div>
              )}
              <div className={styles.ButtonCardPrice} onClick={onShowProgram}>
                {t("pages.partner.programInfo.continuePromoter")}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {showDocument ? (
            <Document
              file={PartnerProposalFile}
              onLoadSuccess={onDocumentLoadSuccess}
              className={styles.container}
            >
              {new Array(numPages).fill("page_").map((value, index) => (
                <Page
                  pageNumber={index + 1}
                  key={value + index}
                  className={
                    index === 0
                      ? "border-white"
                      : index === 1
                      ? "border-blue"
                      : ""
                  }
                />
              ))}
              <div className={styles.cover} />
            </Document>
          ) : (
            <VideoBanner
              title={t("pages.partner.programInfo.communityFraventsPartners")}
              description={t("pages.partner.programInfo.descriptionPartners")}
              image={require("../../assets/images/dummy/banner1.png")}
              video={require("../../assets/videos/Intro_socios_promotores.mp4")}
              onComplete={onCompleteVideo}
              seconds2complete={
                process.env.NODE_ENV === "development" ||
                window?.location?.host?.includes?.("test.")
                  ? 1
                  : 30
              }
            />
          )}

          <div
            className={[
              styles.showFormButton,
              showDocument || localWatched === "done"
                ? ""
                : styles.disableFormButton,
            ].join(" ")}
            onClick={onShowForm}
          >
            {t(
              `pages.partner.programInfo.${
                localWatched === "done" && !showDocument
                  ? "continue"
                  : showDocument
                  ? "showForm"
                  : "cannotContinue"
              }`
            )}
          </div>
        </>
      )}

      <DialogForm
        show={showForm && !state.loading}
        title={t("pages.partner.programInfo.completeDataBefore")}
        text={t("pages.partner.programInfo.completeDataMessage")}
        subtext={t("pages.partner.programInfo.subCompleteDataMessage")}
        leftBtText={t("commons.cancel")}
        funcLeft={() => {
          changeVc(initialValues);
          setShowForm(false);
        }}
        rightBtText={t("commons.accept")}
        funcRight={onSendForm}
        inputs={[
          { label: t("commons.name"), value: "name" },
          { label: t("commons.lastName"), value: "lastName" },
          {
            label: t("commons.email"),
            value: "email",
            type: "email",
            disabled: true,
          },
          { label: t("commons.phone"), value: "phone" },
        ]}
        vc={vc}
        changeVc={changeVc}
        subtextLink={returnCurrentLink(
          Routes.RENDER_LEGAL_DOC + "?document=data"
        )}
      />

      <DialogForm
        show={vc.openConfirmCode && !state.loading}
        title={t("pages.partner.programInfo.beforeContinue")}
        text={t("pages.partner.programInfo.mustAcceptTerms")}
        textLink={returnCurrentLink(
          Routes.RENDER_LEGAL_DOC + "?document=promoter"
        )}
        leftBtText={t("pages.partner.programInfo.noLater")}
        funcLeft={() => changeVc({ openConfirmCode: false })}
        rightBtText={t("pages.partner.programInfo.yesAccept")}
        funcRight={onAcceptConfirmCode}
        vc={vc}
        changeVc={changeVc}
      />
    </Layout>
  );
};

export default ProgramInfo;
