import { makeStyles } from "@material-ui/styles";
import Commons from "../../../utils/styles";
import { colors } from "../../../styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexColumn,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
  closeBtn: {
    ...Commons.flexCenter,
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    borderRadius: "50%",
    maxWidth: 36,
    minWidth: 36,
    maxHeight: 36,
    minHeight: 36,
    marginBottom: 18,
    marginRight: 6,
    color: colors.black,
  },
  contForm: {
    backgroundColor: colors.white,
    width: "100%",
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
    paddingTop: 30,
    animation: "$moveUp .25s ease-in-out",
    position: "relative",
    maxHeight: "calc(100vh - 180px)",
    overflowY: "auto",
  },
  label: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "600",
    margin: 0,
    marginBottom: 12,
  },
  labelError: {
    color: colors.themeColor,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "600",
    margin: 0,
    marginBottom: 18,
    marginTop: -12,
  },
  rowTypes: {
    ...Commons.flexRow,
    gap: "6px",
    overflowX: "auto",
    margin: "0px 6px 12px 6px",
  },
  centerTypes: {
    justifyContent: "center",
  },
  btnType: {
    ...Commons.flexColumn,
    ...Commons.defaultButton,
    borderRadius: 6,
    backgroundColor: colors.grayBlack,
    color: colors.black,
    fontWeight: "600",
    gap: "3px",
    padding: "30px 6px",
    fontSize: 12,
    minWidth: 78,
  },
  fewTickets: {
    color: colors.themeColor,
    position: "absolute",
    bottom: 6,
    fontSize: 10,
    fontWeight: "normal",
    whiteSpace: "nowrap",
  },
  typeSlctd: {
    backgroundColor: colors.themeColor,
    color: colors.white,
  },
  disabled: {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  sublabelPrices: {
    textAlign: "center",
    fontSize: 12,
    marginBottom: 18,
  },
  rowQuantity: {
    ...Commons.flexRow,
    justifyContent: "center",
    gap: "6px",
    margin: "0px 6px 24px 6px",
  },
  changeQuantityBtn: {
    ...Commons.flexCenter,
    ...Commons.defaultButton,
    maxWidth: 36,
    minWidth: 36,
    maxHeight: 36,
    minHeight: 36,
    borderRadius: "50%",
    backgroundColor: colors.grayBlack,
    fontWeight: "600",
    fontSize: 18,
    touchAction: "manipulation",
    color: colors.black,
  },
  inputQuantity: {
    backgroundColor: colors.grayBlack,
    fontWeight: "600",
    border: "none",
    outline: "none",
    textAlign: "center",
    padding: 6,
    borderRadius: 6,
    maxWidth: "30%",
  },
  price: {
    ...Commons.flexRow,
    justifyContent: "center",
    alignItems: "center",
    gap: 18,
    margin: "24px auto",
    borderTop: `1px solid ${colors.black}`,
    width: "80%",
    paddingTop: 12,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  paymentButton: {
    ...Commons.defaultButton,
    ...Commons.flexColumn,
    boxShadow: colors.shadow,
    borderRadius: 6,
    backgroundColor: colors.white,
    flex: 1,
    padding: 12,
    alignItems: "flex-start",
    border: `5px solid ${colors.grayBlack}`,
    transition: "all 0.2s",
    maxWidth: 420,
    "&:hover, &.selected": {
      backgroundColor: colors.black,
      borderColor: colors.themeColor,
      "& img.imageWhitePayment": {
        display: 'block',
      },
      "& img.imageBlackPayment": {
        display: 'none',
      },
    },
    "&:hover p, &:hover span, &.selected p, &.selected span": {
      color: colors.white,
    },
    [Commons.smallQuery]: {
      width: "calc(100% - 32px)",
    },
  },
  rowLabelPayementButton: {
    ...Commons.flexRow,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& img": {
      height: 30,
      objectFit: "contain",
      "&.imageWhitePayment": {
        display: 'none',
      },
      "&.imageBlackPayment": {
        display: 'block',
      },
    },
  },
  labelPaymentButton: {
    fontSize: 13,
    color: colors.gray50,
    marginBottom: 6,
    textAlign: "left",
  },
  amountPaymentButton: {
    fontSize: 20,
    color: colors.black,
    margin: 0,
    textAlign: "center",
    width: "100%",
    fontWeight: "700",
  },
  payBtn: {
    ...Commons.defaultButton,
    backgroundColor: colors.themeColor,
    color: colors.white,
    textAlign: "center",
    width: "90%",
    marginLeft: "5%",
    borderRadius: 6,
    padding: "12px 0px",
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 18,
  },
  "@keyframes moveUp": {
    "0%": {
      bottom: -1000,
    },
    "100%": {
      bottom: 0,
    },
  },
  noDisplay: {
    display: "none",
  },
});

export default styles;
