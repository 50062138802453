import React from "react";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  verifyEmail,
  renderShortPrice,
  removeChild,
  returnCurrentLink,
} from "../../utils/validations";
import LocalStorage, {
  returnSessionStorage as SessionStorage,
} from "../../utils/localStorage";
import MercadoPagoScript from "../../components/MercadoPagoScript";
import ContentEvent from "../../components/ContentEvent";
import BannerEvent from "../../components/BannerEvent";
import DialogForm from "../../components/dialogForm";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles/eventStyles";
import SelectQuantity from "./selectQuantity";
import { keyMp } from "../../utils/constants";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const EventPaymentGateway = ({ history }) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const { state } = React.useContext(Store);

  const styles = pageStyles();
  const returnEvent = state.eventSelected || {};
  const [eventInfo, setEventInfo] = React.useState(returnEvent || {});
  const [mercadoPago, setMercadoPago] = React.useState(null);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [pixelPushed, setPixelPushed] = React.useState(false);
  const [vc, setVc] = React.useState({
    artists: returnEvent?.artists || [],
    contacts: [],
    showModal: false,
    interested: false,
    showPrompt: false,
    typeSlctd: null,
    quantity: null,
    showPoster: false,
    showBtnPay: false,
    email: state?.userInfo?.email || "",
    name: "",
    numId: "",
    lastName: "",
    promoCode: "",
    showPromoCode: false,
    showPromptTickets: false,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const bookTickets = async () => {
    try {
      // Get the action to complete.
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const isTest =
        process.env.NODE_ENV === "development" ||
        window?.location?.host?.includes?.("test.")
          ? "yes"
          : null;
      const enablePromoters = eventInfo?.enablePromoters && vc.showBtnPay;
      let ref = enablePromoters ? urlParams.get("ref") : null;
      let external_ref = urlParams.get("external_ref");

      // With local value of promoter saved
      if (!ref && enablePromoters) {
        ref =
          LocalStorage(state.isIncognito).getItem(`@ref_${eventInfo?.uid}`) ||
          null;
      }
      if (!external_ref) {
        external_ref =
          LocalStorage(state.isIncognito).getItem(
            `@external_ref_${eventInfo?.uid}`
          ) || null;
      }
      LocalStorage(state.isIncognito).removeItem(`@ref_${eventInfo?.uid}`);
      LocalStorage(state.isIncognito).removeItem(
        `@external_ref_${eventInfo?.uid}`
      );

      // Avoid use the same user id as contact
      external_ref = external_ref === state.uid ? null : external_ref;
      ref = ref === state.uid ? null : ref;

      const emailLower = vc.email.toLowerCase().replace(/ /g, "");
      const data = {
        isTest,
        name: vc.name,
        lastName: vc.lastName,
        email: emailLower,
        numId: vc.numId,
        quantity: parseInt(vc.quantity),
        typeSlctd: vc.typeSlctd,
        eventSelected: eventInfo?.uid,
        ref: ref,
        isReferred: false,
        promoCode: vc.promoCode,
        external_ref: external_ref,
        currency: state.defaultCurrency,
        fromGateway: true,
      };

      const response = await EventsService.bookAticketWithEmail(data);
      if (
        eventInfo?.prices?.[vc.typeSlctd]?.[
          state.defaultCurrency === "USD" ? "usdPrice" : "price"
        ] === 0
      ) {
        setTimeout(() => {
          Model.setData("dialog", {
            open: true,
            title: t("pages.events.indexEvent.sellRegistered"),
            text: t("pages.events.indexEvent.inSomeMinutesEmail", {
              email: vc.email,
            }),
            txtRight: t("pages.events.indexEvent.gotIt"),
            fnRight: () => {
              Model.setData("dialog", { open: false });
              if (
                eventInfo?.prices?.[vc.typeSlctd]?.price === 0 &&
                !state.isOnPluginPartner
              ) {
                if (eventInfo?.organizer?.redirectUrl) {
                  window.location.href = eventInfo?.organizer?.redirectUrl;
                } else {
                  history.push(Routes.MY_TRANSACTIONS);
                }
              }
            },
            onClose: () => {
              if (
                eventInfo?.prices?.[vc.typeSlctd]?.price === 0 &&
                !state.isOnPluginPartner
              ) {
                if (eventInfo?.organizer?.redirectUrl) {
                  window.location.href = eventInfo?.organizer?.redirectUrl;
                } else {
                  history.push(Routes.MY_TRANSACTIONS);
                }
              }
            },
          });
        }, 1000);
      } else if (response.url) {
        window.open(response.url, "_self");
      } else if (!response.idPreference || !mercadoPago) {
        throw new Error(
          t("pages.events.indexEvent.noAvailableBooking", {
            error: response || t("pages.events.indexEvent.errorData"),
          })
        );
      } else {
        // Go to Pay
        setTimeout(() => {
          mercadoPago.checkout({
            preference: {
              id: response.idPreference,
            },
            autoOpen: true,
          });
        }, 1000);
      }
    } catch (e) {
      Model.updateAlerts({
        message: e?.message || String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      Model.setData("dialog", { open: false });
    }
  };

  const onSelectTickets = (typeSlctd, quantity) => {
    if (parseInt(quantity) > 0 && typeSlctd !== null) {
      if (
        (eventInfo?.prices?.length === 1 &&
          eventInfo?.prices?.[0]?.price === 0) ||
        state.defaultCurrency === "USD"
      ) {
        changeVc({
          showPrompt: true,
          showModal: false,
          typeSlctd,
          quantity,
          email: "",
          name: "",
          numId: "",
          lastName: "",
          promoCode: "",
          showPromoCode: false,
        });
      } else {
        Model.setData("dialog", {
          open: true,
          title: t("pages.events.indexEvent.promoCode"),
          text: t("pages.events.indexEvent.youHaveCode"),
          txtLeft: t("commons.no"),
          fnLeft: () => {
            Model.setData("dialog", { open: false });
            changeVc({
              showPrompt: true,
              showModal: false,
              typeSlctd,
              quantity,
              email: "",
              name: "",
              numId: "",
              lastName: "",
              promoCode: "",
              showPromoCode: false,
            });
          },
          txtRight: t("commons.yes"),
          fnRight: () => {
            Model.setData("dialog", { open: false });
            changeVc({
              showPrompt: true,
              showModal: false,
              typeSlctd,
              quantity,
              email: "",
              name: "",
              numId: "",
              lastName: "",
              promoCode: "",
              showPromoCode: true,
            });
          },
        });
      }
    } else {
      Model.updateAlerts({
        message: t("pages.events.indexEvent.completeData"),
        variant: "error",
      });
    }
  };

  const showConfirmDialog = () => {
    changeVc({ showPrompt: false });
    Model.setData("dialog", {
      open: true,
      title: t("pages.events.indexEvent.confirmSelection"),
      text: t("pages.events.indexEvent.youWantBuyTickets", {
        name: vc.name,
        action: t("pages.events.indexEvent.purchase"),
        quantity: vc.quantity,
        multiple: vc.quantity > 1 ? "s" : "",
        ticketName: eventInfo?.prices?.[vc.typeSlctd]?.label || "ERROR",
        description:
          eventInfo?.prices?.[vc.typeSlctd]?.price === 0
            ? t("pages.events.indexEvent.dataWillSave", { email: vc.email })
            : t("pages.events.indexEvent.youWillBeRedirect"),
      }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: bookTickets,
    });
  };

  const confirmPromoCode = async () => {
    try {
      Model.setData("loading", true);
      const emailLower = vc.email.toLowerCase().replace(/ /g, "");

      const responseConfirmPromoCode =
        await EventsService.confirmPromoCodeWithEmail({
          uidEvent: eventInfo?.uid,
          promoCode: vc.promoCode,
          email: emailLower,
          name: vc.name,
          lastName: vc.lastName,
        });

      Model.setData("dialog", {
        open: true,
        title: t("pages.events.indexEvent.confirmDiscount"),
        text: t("pages.events.indexEvent.infoPromoCodeEntered", {
          name: vc.promoCode,
          discount: responseConfirmPromoCode?.valuePromoCode
            ? `$${renderShortPrice(
                responseConfirmPromoCode?.valuePromoCode
              )} COP`
            : `${responseConfirmPromoCode?.percentPromoCode}%`,
        }),
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("pages.events.indexEvent.yesPay"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
          setTimeout(showConfirmDialog, 500);
        },
      });
    } catch (e) {
      console.log(e);
      Model.updateAlerts({
        message:
          t("pages.events.indexEvent.errorPromoCode") +
          (e?.message || e?.error || String(e)),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSendForm = () => {
    if (!vc.name || !vc.lastName || !vc.numId || !vc.email) {
      Model.updateAlerts({
        message: t("pages.events.indexEvent.completeData"),
        variant: "error",
      });
    } else if (!verifyEmail(vc.email)) {
      Model.updateAlerts({
        message: t("pages.events.indexEvent.emailHasBadFormat"),
        variant: "error",
      });
    } else if (vc.promoCode) {
      confirmPromoCode();
    } else {
      showConfirmDialog();
    }
  };

  const onBuyTickets = () => {
    if (
      ((eventInfo?.organizer?.secret || eventInfo?.organizer?.bitcoin) &&
        eventInfo.acceptOnlinePayments) ||
      (eventInfo?.prices?.length === 1 && eventInfo?.prices?.[0]?.price === 0)
    ) {
      changeVc({ showModal: true });
    } else {
      Model.setData("dialog", {
        open: true,
        title: t("pages.events.indexEvent.buyTickets"),
        text: t("pages.events.indexEvent.partnerNoCanSell"),
        txtLeft: eventInfo?.organizer?.phone
          ? t("pages.events.indexEvent.goToWhatsapp")
          : "",
        fnLeft: () => {
          Model.setData("dialog", { open: false });
          window.open(
            "https://wa.me/" +
              eventInfo?.organizer?.phone?.replace(/[^0-9]/g, ""),
            "_blank"
          );
        },
        txtRight: t("pages.events.indexEvent.gotIt"),
        fnRight: () => Model.setData("dialog", { open: false }),
      });
    }
  };
  const getContacts = async () => {
    try {
      const ref = urlParams.get("ref");
      const external_ref = urlParams.get("external_ref");
      let refIsReal = false;
      LocalStorage(state.isIncognito).setItem(
        `@external_ref_${eventInfo?.uid}`,
        external_ref
      );
      // Promoters
      const responseContacts = eventInfo?.contacts || [];
      const withCollaborators = [
        ...responseContacts,
        ...(eventInfo?.collaborators || []),
      ];
      withCollaborators.forEach((uid) => {
        if (uid === ref) {
          refIsReal = true;
          LocalStorage(state.isIncognito).setItem(
            `@ref_${eventInfo?.uid}`,
            ref
          );
        }
      });
      changeVc({ contacts: responseContacts.sort(() => Math.random() - 0.5) });
      if (!!ref && refIsReal) {
        changeVc({ showBtnPay: true });
      }
    } catch (e) {
      console.log(t("pages.events.indexEvent.errorGetContacts"), e);
    }
  };

  const getEvent = async (uid) => {
    try {
      Model.setData("loading", true);
      const infoEvent = await EventsService.getEvent(uid);
      changeVc({
        artists: infoEvent?.artists || [],
      });
      setEventInfo(infoEvent);
      Model.setData("eventSelected", infoEvent);
      if (
        state?.infoPartner?.uid &&
        state?.infoPartner?.logo &&
        !state.fistLoad
      ) {
        Model.setData("isWhiteLabel", !!infoEvent?.organizer?.isWhiteLabel);
      }
    } catch (e) {
      console.log(t("pages.events.indexEvent.errorGetEvents"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  const goToMyTickets = () => {
    Model.setData("dialog", {
      open: true,
      title: "Antes de continuar",
      text: 'Este evento maneja la boletería digital con la plataforma www.fravents.com, por lo tanto para ver tus tickets debes inicar sesión con el email que registraste en tu compra y puedes ver tus tickets en la sección "Mis compras". Cualquier inquietud nos puedes contactar.',
      txtLeft: "Cerrar",
      fnLeft: () => {
        Model.setData("dialog", { open: false });
      },
      txtRight: "Iniciar sesión en Fravents",
      fnRight: () => {
        Model.setData("dialog", { open: false });
        history.push(Routes.MY_TRANSACTIONS);
      },
    });
  };

  const renderBarActionsPayment = () =>
    parseInt(eventInfo.date) < parseInt(moment().format("x")) ? null : (
      <div className={styles.contBuy}>
        <button className={styles.buttonViewTickets} onClick={goToMyTickets}>
          Mis Tickets
        </button>
        {eventInfo.status === "finished" ? (
          <div className={styles.lblPrivate}>
            {t("pages.events.indexEvent.eventFinished")}
          </div>
        ) : eventInfo?.private && !vc.showBtnPay ? (
          <div className={styles.lblPrivate}>
            {t("pages.events.indexEvent.getTicketsByLink")}
          </div>
        ) : (
          <button className={styles.buttonBuy} onClick={onBuyTickets}>
            {eventInfo?.prices?.length === 1 &&
            eventInfo?.prices?.[0]?.price === 0
              ? t("pages.events.indexEvent.registerFree")
              : t("pages.events.indexEvent.buyTickets")}
          </button>
        )}
      </div>
    );

  React.useLayoutEffect(() => {
    // Facebook Pixel
    const scriptTag = document.createElement("script");
    const noScriptTag = document.createElement("noscript");
    const imgTag = document.createElement("img");

    if (eventInfo?.fbPx && !pixelPushed) {
      imgTag.height = 1;
      imgTag.width = 1;
      imgTag.src = `https://www.facebook.com/tr?id=${eventInfo?.fbPx}&ev=PageView&noscript=1`;

      scriptTag.innerHTML = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${eventInfo?.fbPx}'); 
      fbq('track', 'PageView');`;

      noScriptTag.appendChild(imgTag);

      document.getElementsByTagName("head")[0].appendChild(scriptTag);
      document.getElementsByTagName("head")[0].appendChild(noScriptTag);

      setPixelPushed(true);
    }
    return () => {
      const allTags = document.getElementsByTagName("script");
      removeChild(scriptTag);
      removeChild(noScriptTag);
      for (let i = 0; i < allTags.length; i++) {
        if (allTags[i].src.includes("fbevents")) {
          removeChild(allTags[i]);
        }
        if (allTags[i].src.includes("connect.facebook.net/signals")) {
          removeChild(allTags[i]);
        }
      }
    };
    //eslint-disable-next-line
  }, [eventInfo?.fbPx]);

  React.useEffect(() => {
    if (firstLoad) {
      Model.setData("opacity", 0.7);
      Model.setData("loading", false);
      setFirstLoad(false);
    } else {
      const event = urlParams.get("event");
      if (!event && state?.eventSelected?.uid) {
        SessionStorage(state.isIncognito).setItem(
          state.eventSelected.uid,
          JSON.stringify(state.eventSelected)
        );
        return (window.location.href += `?event=${state.eventSelected.uid}`);
      } else if (state.eventSelected) {
        setEventInfo(state.eventSelected);
        // EventsService.logEvent(state?.eventSelected?.name)
        //   .then(() => {})
        //   .catch(() => {});
        getContacts();
        if (!state.eventSelected?.organizer) {
          getEvent(state.eventSelected.uid);
        }
      } else if (event) {
        getEvent(event);
      } else {
        history.push("/");
      }
    }

    //eslint-disable-next-line
  }, [firstLoad, state.eventSelected, state.uid, state.userInfo]);

  return (
    <Layout hideFooterLinks={true} hideHeader={true} showPowered>
      <MercadoPagoScript
        onEndLoad={() => {
          setMercadoPago(
            new window.MercadoPago(keyMp, {
              locale: "es-CO",
            })
          );
        }}
      />

      <BannerEvent
        eventInfo={eventInfo}
        changeVc={changeVc}
        history={history}
        BarActions={renderBarActionsPayment}
      />

      <ContentEvent
        eventInfo={eventInfo}
        artists={vc.artists}
        contacts={vc.contacts}
        history={history}
        hideSellOffers
      />

      <SelectQuantity
        onClose={() => changeVc({ showModal: false })}
        onDone={onSelectTickets}
        data={eventInfo}
        show={vc.showModal}
      />

      <DialogForm
        show={vc.showPromptTickets && !state.loading}
        title={"Completa la siguiente información"}
        text={
          "Por favor ingresa el correo electrónico con el cual registraste la compra en la plataforma y te enviaremos las instrucicones para ver tus tickets. Si tienes algún inconveniente te puedes contactar con nosotros para darte una pronta solución."
        }
        leftBtText={t("commons.cancel")}
        funcLeft={() =>
          changeVc({
            showPromptTickets: false,
            email: "",
          })
        }
        rightBtText={t("commons.accept")}
        funcRight={() => {}}
        inputs={[
          {
            label: t("commons.email"),
            value: "email",
            type: "email",
          },
        ]}
        vc={vc}
        changeVc={changeVc}
      />

      <DialogForm
        show={vc.showPrompt && !state.loading}
        title={t("pages.events.indexEvent.completeBuyData")}
        text={t("pages.events.indexEvent.toContinueWithBuy", {
          quantity: vc.quantity,
          multiple: vc.quantity > 1 ? "s" : "",
          type: eventInfo?.prices?.[vc.typeSlctd]?.label || "Cortesia",
        })}
        subtext={t("pages.events.indexEvent.toSendDataAcceptTC", {
          provider:
            state.defaultCurrency === "USD" ? "Cryptomus" : "Mercado Pago",
        })}
        subtextLink={returnCurrentLink(
          Routes.RENDER_LEGAL_DOC + "?document=buyers"
        )}
        leftBtText={t("commons.cancel")}
        funcLeft={() =>
          changeVc({
            showPrompt: false,
            email: "",
            name: "",
            numId: "",
            lastName: "",
            promoCode: "",
          })
        }
        rightBtText={t("commons.accept")}
        funcRight={onSendForm}
        inputs={[
          { label: t("commons.name"), value: "name" },
          { label: t("commons.lastName"), value: "lastName" },
          {
            label: t("commons.email"),
            value: "email",
            type: "email",
          },
          { label: t("commons.numId"), value: "numId" },
          vc.showPromoCode &&
          parseInt(
            (state.defaultCurrency === "COP" &&
              eventInfo?.prices?.[vc.typeSlctd]?.price) ||
              (state.defaultCurrency === "USD" &&
                eventInfo?.prices?.[vc.typeSlctd]?.usdPrice) ||
              "0"
          ) > 0
            ? {
                label: t("pages.events.indexEvent.promoCode"),
                value: "promoCode",
              }
            : null,
        ]}
        vc={vc}
        changeVc={changeVc}
      />
      {vc.showPoster ? (
        <div
          className={styles.modalPoster}
          onClick={() => changeVc({ showPoster: false })}
        >
          <button
            className={styles.btnClosePoster}
            onClick={() => changeVc({ showPoster: false })}
          >
            <Close />
          </button>
          <img
            className={styles.imgPoster}
            alt="Poster"
            src={eventInfo.imageBanner}
          />
        </div>
      ) : null}
    </Layout>
  );
};

export default EventPaymentGateway;
