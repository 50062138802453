/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import pageStyles from "./styles";

const BannerHome = () => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const sources = {
    media: [
      require("../../assets/images/dummy/banner1v2.png"),
      require("../../assets/images/dummy/banner2v2.png"),
      require("../../assets/images/dummy/banner3v2.png"),
      require("../../assets/images/dummy/banner4v2.png"),
    ],
    texts: [
      t("components.bannerHome.revolutionizeEvents"),
      t("components.bannerHome.transferResale"),
      t("components.bannerHome.manageEvents"),
      t("components.bannerHome.offerSafe"),
    ],
  };
  const [vc, setVc] = React.useState({
    step: -1,
    text: "",
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const typeWords = () => {
    return setTimeout(async () => {
      const nextStep = vc.step + 1 >= sources.media.length ? 0 : vc.step + 1;
      let auxText = vc.text;
      let counter = 0;
      let changeDirection = false;
      while (auxText !== sources.texts[nextStep]) {
        await new Promise((res) => {
          if (auxText.length < 1) {
            changeDirection = true;
          }
          setTimeout(() => {
            auxText = changeDirection
              ? `${auxText}${sources.texts[nextStep][counter]}`
              : auxText.slice(0, -1);
            changeVc({
              text: auxText,
            });
            if (changeDirection) {
              counter++;
            }
            if (auxText === sources.texts[nextStep]) {
              changeVc({ step: nextStep });
            }
            res();
          }, 20);
        });
      }
    }, 5000);
  };

  React.useEffect(() => {
    const timer = typeWords();
    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, [vc.step]);

  return (
    <>
      <div className={styles.bannerInfo}>
        <img
          src={sources.media[vc.step < 0 ? 0 : vc.step]}
          alt="Banner home"
          className={styles.imageBannerInfo}
        />
        <div className={styles.filterImageBannerInfo} />
        <div className={styles.textBannerInfo}>
          <h1 className={styles.introText}>
            <em>{vc.text}</em>
          </h1>
          <h2 className={styles.subIntro}>
            {t("components.bannerHome.andCentralize")}{" "}
            <b>{t("components.bannerHome.free")}</b>
          </h2>
          <div className={styles.rowButtons}>
            <Link className={styles.ctaBannerInfo} to="/contactPublish">
              {t("components.bannerHome.addEvent")}
            </Link>
            <div className={styles.orLabel}>- o -</div>
            <Link className={styles.ctaBannerInfo} to="/allEvents">
              {t("components.bannerHome.viewEvents")}
            </Link>
          </div>
        </div>
        <div className={styles.shadowBanner} />
      </div>
    </>
  );
};

export default BannerHome;
