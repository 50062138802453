import React from "react";
import {
  CheckCircle,
  Edit,
  Event,
  Group,
  InsertChart,
  EmojiEvents,
} from "@material-ui/icons";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";

import FraventsLogo from "../../assets/images/Iso-blanco.svg";
import { returnCurrentLink } from "../../utils/validations";
import pageStyles from "./styles/contactPublishStyles";
import DialogForm from "../../components/dialogForm";
import Features from "../../components/Features";
import AdminService from "../../services/admin";
import { Store } from "../../hooks/main_store";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";
import Layout from "../layout";

const ContactPublish = ({ history }) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const styles = pageStyles();

  const [vc, setVc] = React.useState({
    openForm: false,
    promoterCode: "",
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const renderFuncionalities = (isFree = true) => {
    const data = isFree
      ? [
          t("pages.admin.contactPublish.createTypeEvent"),
          t("pages.admin.contactPublish.manageDifferentEventTypes"),
          t("pages.admin.contactPublish.enterPlatformAdmin"),
          t("pages.admin.contactPublish.seeAnalyticPlatform"),
          t("pages.admin.contactPublish.managePromotersTeam"),
          t("pages.admin.contactPublish.delegateLogictisTeam"),
          t("pages.admin.contactPublish.keepInfoCentralized"),
          t("pages.admin.contactPublish.continueWork"),
        ]
      : [
          t("pages.admin.contactPublish.includeAllPlan"),
          t("pages.admin.contactPublish.clientAssumeTax"),
          t("pages.admin.contactPublish.withoutSuscription"),
          t("pages.admin.contactPublish.linkMPaccountEarn"),
          t("pages.admin.contactPublish.taxIncludeAll"),
          t("pages.admin.contactPublish.offerPaymentMethods"),
          t("pages.admin.contactPublish.sellTicketsWithouLimit"),
          t("pages.admin.contactPublish.youHaveOwnWebShop"),
          t("pages.admin.contactPublish.sellAnyProduct"),
        ];
    return data.map((item, index) => (
      <div className={styles.rowFunction} key={"item-index-" + index}>
        <CheckCircle className={styles.iconFunction} />
        <p className={styles.textFunction}>{item}</p>
      </div>
    ));
  };

  const renderTimeline = () => (
    <>
      <TimelineItem>
        <TimelineOppositeContent>
          <div className={styles.labelStep}>
            {t("pages.admin.contactPublish.step")} #1
          </div>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <Edit />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper className={styles.contTextStep} elevation={3}>
            <div className={styles.titleStep}>
              {t("pages.admin.contactPublish.createAccount")}
            </div>
            <div className={styles.textStep}>
              {t("pages.admin.contactPublish.simpleSteps")}
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <div className={styles.labelStep}>
            {t("pages.admin.contactPublish.step")} #2
          </div>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <Event />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper className={styles.contTextStep} elevation={3}>
            <div className={styles.titleStep}>
              {t("pages.admin.contactPublish.setUpEvent")}
            </div>
            <div className={styles.textStep}>
              {t("pages.admin.contactPublish.addNeededInfo")}
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <div className={styles.labelStep}>
            {t("pages.admin.contactPublish.step")} #3
          </div>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="outlined">
            <Group />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper className={styles.contTextStep} elevation={3}>
            <div className={styles.titleStep}>
              {t("pages.admin.contactPublish.manageTeam")}
            </div>
            <div className={styles.textStep}>
              {t("pages.admin.contactPublish.addPromotersLogistics")}
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <div className={styles.labelStep}>
            {t("pages.admin.contactPublish.step")} #4
          </div>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <InsertChart />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper className={styles.contTextStep} elevation={3}>
            <div className={styles.titleStep}>
              {t("pages.admin.contactPublish.supervise")}
            </div>
            <div className={styles.textStep}>
              {t("pages.admin.contactPublish.ourPlatformAnalytics")}
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <div className={styles.labelStep}>
            {t("pages.admin.contactPublish.step")} #5
          </div>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary" variant="outlined">
            <EmojiEvents />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper className={styles.contTextStep} elevation={3}>
            <div className={styles.titleStep}>
              {t("pages.admin.contactPublish.enjoyRepeat")}
            </div>
            <div className={styles.textStep}>
              {t("pages.admin.contactPublish.weWantBest")}{" "}
              <span className={styles.textLinkStep}>
                {t("pages.admin.contactPublish.termsConditions")}
              </span>
            </div>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </>
  );

  const onAcceptPartner = async () => {
    try {
      changeVc({ openForm: false });
      Model.setData("loading", true);
      await AdminService.acceptPartner({ promoterCode: vc.promoterCode });
      Model.updateUserInfo({ isPartner: true });
      history.push("/admin");
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.admin.contactPublish.errorSaveParnet")} ${String(
          e
        )}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onAdmin = () => {
    if (state?.userInfo?.isPartner) {
      history.push("/admin");
    } else if (state?.emailVerified) {
      // Model.setData("dialog", {
      //   open: true,
      //   title: t("pages.admin.contactPublish.beforeContinue"),
      //   text: t("pages.admin.contactPublish.mustAcceptTerms"),
      //   txtLeft: t("pages.admin.contactPublish.noLater"),
      //   fnLeft: () => Model.setData("dialog", { open: false }),
      //   txtRight: t("pages.admin.contactPublish.yesAccept"),
      //   fnRight: onAcceptPartner,
      // });

      changeVc({ openForm: true, promoterCode: "" });
    } else if (!state?.uid) {
      Model.setData("openAuth", true);
    } else {
      history.push("/profile");
    }
  };

  const renderWantButton = () => {
    return state?.emailVerified ? (
      <div className={styles.buttonCta} onClick={onAdmin}>
        {state?.userInfo?.isPartner
          ? t("pages.admin.contactPublish.viewAdminAccount")
          : t("pages.admin.contactPublish.createParnertAccount")}
      </div>
    ) : (
      <div
        className={[styles.buttonCta, styles.disabledBtn].join(" ")}
        onClick={onAdmin}
      >
        {state?.uid
          ? t("pages.admin.contactPublish.mustVerifyEmail")
          : t("pages.admin.contactPublish.logInContinue")}
      </div>
    );
  };

  React.useEffect(() => {
    Model.setData("loading", false);
    Model.setData("superLoading", false);
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <h1 className={styles.title}>
        {t("pages.admin.contactPublish.publishEvent")}
      </h1>

      <div className={styles.contLogo}>
        <img alt="Fravents" src={FraventsLogo} className={styles.logo} />
      </div>

      <p className={styles.description}>
        {t("pages.admin.contactPublish.hiThanks")}{" "}
        <a href="mailto:help@fravents.com">help@fravents.com</a>{" "}
        {t("pages.admin.contactPublish.withBriefMessage")}
        <br />
        <br /> {t("pages.admin.contactPublish.bestRegards")}
      </p>

      <h2 className={styles.title}>
        {t("pages.admin.contactPublish.lookFuntionalities")}
      </h2>
      <div className={styles.rowFeatures}>
        <Features history={history} showFeatures />
      </div>

      {renderWantButton()}
      <div className={styles.blank} />

      <h2 className={styles.title}>
        {t("pages.admin.contactPublish.manageTickets")}
      </h2>
      <div className={styles.rowFeatures}>
        <div className={styles.itemFeature}>
          <h3 className={styles.titleItemFeature}>
            {t("pages.admin.contactPublish.manual")}
          </h3>
          <p className={styles.descriptionItemFeature}>
            {t("pages.admin.contactPublish.ifSellOut")}
          </p>
        </div>
        <div className={styles.itemFeature}>
          <h3 className={styles.titleItemFeature}>
            {t("pages.admin.contactPublish.refer")}
          </h3>
          <p className={styles.descriptionItemFeature}>
            {t("pages.admin.contactPublish.allMemberHaveLink")}
          </p>
        </div>
        <div className={styles.itemFeature}>
          <h3 className={styles.titleItemFeature}>
            {t("pages.admin.contactPublish.automatic")}
          </h3>
          <p className={styles.descriptionItemFeature}>
            {t("pages.admin.contactPublish.noWorryAboutProcess")}
          </p>
        </div>
      </div>

      <div className={styles.blank} />

      <h2 className={styles.title}>
        {t("pages.admin.contactPublish.stepByStepAccount")}
      </h2>
      <Timeline align="alternate" className={styles.stepsContainer}>
        {renderTimeline()}
      </Timeline>
      <Timeline
        className={[styles.stepsContainer, styles.showOnMobile].join(" ")}
      >
        {renderTimeline()}
      </Timeline>

      <div className={styles.blank} />

      <h2 className={styles.title}>
        {t("pages.admin.contactPublish.ourPlans")}
      </h2>

      <div className={styles.row}>
        <div className={styles.cardPrice}>
          <div className={styles.titleCardPrice}>
            {t("pages.admin.contactPublish.freePlan")}
          </div>
          <p className={styles.textCardPrice}>
            {t("pages.admin.contactPublish.ourCommunityIsUnique")}
          </p>
          <div className={styles.amountCardPrice}>
            {t("pages.admin.contactPublish.zeroFree")}
          </div>
          <div className={styles.ButtonCardPrice}>
            {t("pages.admin.contactPublish.isYours")}
          </div>
          {renderFuncionalities(true)}
        </div>
        <div className={styles.cardPrice}>
          <div className={styles.titleCardPrice}>
            {t("pages.admin.contactPublish.payedplan")}
          </div>
          <p className={styles.textCardPrice}>
            {t("pages.admin.contactPublish.forInterested")}
          </p>
          <div className={styles.amountCardPrice}>
            {t("pages.admin.contactPublish.aproxTax")}
          </div>
          <div className={styles.ButtonCardPrice}>
            {t("pages.admin.contactPublish.contactUs")}
          </div>
          {renderFuncionalities(false)}
          <div className={styles.textNote}>
            {t("pages.admin.contactPublish.ifSellInOurPlatform")}
          </div>
        </div>
      </div>

      {renderWantButton()}

      <div className={styles.blank} />

      <DialogForm
        show={vc.openForm && !state.loading}
        title={t("pages.admin.contactPublish.beforeContinue")}
        text={t("pages.admin.contactPublish.mustAcceptTerms")}
        textLink={returnCurrentLink(
          Routes.RENDER_LEGAL_DOC + "?document=organizer"
        )}
        subtext={t("pages.admin.contactPublish.promoterCodeInfo")}
        leftBtText={t("pages.admin.contactPublish.noLater")}
        funcLeft={() => changeVc({ openForm: false, promoterCode: "" })}
        rightBtText={t("pages.admin.contactPublish.yesAccept")}
        funcRight={onAcceptPartner}
        inputs={[
          {
            label: t("pages.admin.contactPublish.promoterCode"),
            value: "promoterCode",
          },
        ]}
        vc={vc}
        changeVc={changeVc}
      />
    </Layout>
  );
};

export default ContactPublish;
